import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const GardensContext = createContext();

export function useGardens() {
  return useContext(GardensContext);
}

export const GardensProvider = ({children}) => {
    const {api} = useConfig();

    const [gardens, setGardens] = useState([]);
    const [featuredGardens, setFeaturedGardens] = useState([]);

    const {user} = useUser();
    const userId = user.id;

    const [garden, setGarden] = useState([]);
    const [gardenPlants, setGardenPlants] = useState([]);
    // const [gardenUpdates, setGardenUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isGardenError, setIsGardenError] = useState(false);
    const [addGardenSuccess, setAddGardenSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/gardens`, {userId}).then(result =>
            result.json().then(data => {
                setGardens(data);
            })
        );

        fetch(`${api}/user/${userId}/featured/gardens`, {userId}).then(result =>
        result.json().then(data => {
            setFeaturedGardens(data);
        })
        );

    }, [userId, api]);


    function addGarden(newGarden) {
        return axios.post(`${api}/garden/add/${userId}`, {
            ...newGarden,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsGardenError(false);
                setAddGardenSuccess(true);
                setGardens([...gardens, result.data]);
                setTimeout(() => {
                    setAddGardenSuccess(false);
                }, 5000);
                return(result.data.id);
            } else {
                setIsGardenError(true);
            }
        }).catch(e => {
            setIsGardenError(true);
            window.alert(e);
        });
    }

    function addGardenUsers(gardenId, data, sendEmails) {
        return axios.post(`${api}/garden/${gardenId}/update-users`, {data, sendEmails});
    }

    function editGarden(onHide = false, data) {
        return axios.post(`${api}/garden/${data.gardenId}/edit`, {...data})
        .then((result) => {
            let newGardens = [...gardens];
            if (gardens.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newGardens = [result.data, ...gardens];
            } else {
                newGardens[gardens.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setGardens(newGardens);
            onHide(false);
        })
      }

    function removeGarden(onHide, gardenId) {
        fetch(`${api}/garden/remove/${gardenId}`, {
            gardenId
        }).then(result => {
            if (result.status === 200) {
                var newGardens = gardens.filter(function(oldGardens) { return oldGardens.id !== gardenId });
                setGardens(newGardens);
                onHide();
            } else {
                setIsGardenError(true);
            }
        }).catch(e => {
            console.log(e);
            setIsGardenError(true);
        });
    }

    function getFullGarden(id) {
        setIsLoading(true);
        fetch(`${api}/garden/${id}/get`, {id}).then(result =>
        result.json().then(data => {
            setGarden(data);
        }));
        fetch(`${api}/garden/${id}/plants`, {id}).then(result =>
        result.json().then(data => {
            setGardenPlants(data);
            setIsLoading(false);
        }));
        // fetch(`${api}/garden/${id}/updates`, {id}).then(result =>
        // result.json().then(data => {
        //     setGardenUpdates(data);
        // }));
    }

    return (
    <GardensContext.Provider value={{gardens, featuredGardens, garden, gardenPlants, getFullGarden, setGardens, addGarden, addGardenUsers, editGarden, removeGarden, isGardenError, addGardenSuccess, setIsGardenError, setAddGardenSuccess, isLoading}}>
        {children}
    </GardensContext.Provider>
    )
}

export const withGarden = (Wrapped) => (props) => {
    return (<GardensProvider>
        <Wrapped {...props} />
    </GardensProvider>)
}