import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const ToolsContext = createContext();

export function useTools() {
  return useContext(ToolsContext);
}

export const ToolsProvider = ({children}) => {
    const {api} = useConfig();

    const [tools, setTools] = useState([]);
    const {user} = useUser();
    const userId = user.id;

    const [isToolsError, setIsToolsError] = useState(false);
    const [addToolSuccess, setAddToolSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/tools`, {userId}).then(result =>
            result.json().then(data => {
                setTools(data);
            })
        );
    }, [userId, api]);

    function loanTool(cb, toolId, newUserId, expiry) {
        axios.post(`${api}/tool/${toolId}/loan/`, {
            userId,
            newUserId,
            expiry: expiry.getTime()
        }).then(result => {
            if (result.status === 200) {
                // var newTools = tools.filter(function(oldTools) { return oldTools.id !== toolId });
                // setTools(newTools);
                cb(false);
            } else {
                setIsToolsError(true);
            }
        }).catch(e => {
            setIsToolsError(true);
        });
    }

    function addTool(newTool) {
        return axios.post(`${api}/add_tool`, {
            ...newTool,
            userId
        }).then(result => {
            if (result.status === 200) {
                setTools([result.data, ...tools]);
                return(result.data.id);
            } else {
                setIsToolsError(true);
            }
        }).catch(e => {
            setIsToolsError(true);
        });
    }

    function removeTool(onHide, toolId) {
        fetch(`${api}/tool/${toolId}/remove/`, {
            toolId
        }).then(result => {
            if (result.status === 200) {
                let newTools = tools.filter(function(oldTools) {return oldTools.id !== toolId});
                setTools(newTools);
                onHide();
            } else {
                setIsToolsError(true);
            }
        });
    }

    function editTool(cb, data) {
        axios.post(`${api}/tool/${data.newToolId}/edit/`, {...data})
        .then((result) => {
            let newTools = [...tools];
            if (tools.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newTools = [result.data, ...tools];
            } else {
                newTools[tools.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setTools(newTools);
            cb(false);
        })
    }

    return (
    <ToolsContext.Provider value={{tools, setTools, addTool, loanTool, editTool, removeTool, isToolsError, addToolSuccess, setIsToolsError, setAddToolSuccess}}>
        {children}
    </ToolsContext.Provider>
    )
}