import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const SharesContext = createContext();

export function useShares() {
  return useContext(SharesContext);
}

export const SharesProvider = ({children}) => {
    const {api} = useConfig();

    const [shares, setShares] = useState([]);
    const [communityShares, setCommunityShares] = useState([]);
    const [shareRequests, setShareRequests] = useState([]);

    const {user} = useUser();
    const userId = user.id;

    const [isShareError, setIsShareError] = useState(false);
    const [addShareSuccess, setAddShareSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/shares`, {userId}).then(result =>
            result.json().then(data => {
                setShares(data);
            })
        );

        fetch(`${api}/user/${userId}/community_shares`, {userId}).then(result =>
          result.json().then(data => {
              setCommunityShares(data);
          })
        );

        fetch(`${api}/user/${userId}/share_requests`, {userId}).then(result =>
            result.json().then(data => {
                setShareRequests(data);
            })
        );

    }, [userId, api]);


    function addShare(newShare) {
        return axios.post(`${api}/share/add/${userId}`, {
            ...newShare,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsShareError(false);
                setAddShareSuccess(true);
                setShares([...shares, result.data]);
                setTimeout(() => {
                    setAddShareSuccess(false);
                }, 5000);
                return(result.data.id);
            } else {
                setIsShareError(true);
            }
        }).catch(e => {
            setIsShareError(true);
            window.alert(e);
        });
    }


    function addShareRequest(shareRequest, onHide) {
        return axios.post(`${api}/share/request`, {
            ...shareRequest,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsShareError(false);
                setAddShareSuccess(true);
                setShareRequests([...shareRequests, result.data]);
                onHide();
                setTimeout(() => {
                    setAddShareSuccess(false);
                }, 5000);
                return(result.data.id);
            } else {
                setIsShareError(true);
            }
        }).catch(e => {
            setIsShareError(true);
            window.alert(e);
        });
    }


    function editShare(onHide = false, data) {
        return axios.post(`${api}/share/${data.shareId}/edit`, {...data})
        .then((result) => {
            let newShares = [...shares];
            if (shares.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newShares = [result.data, ...shares];
            } else {
                newShares[shares.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setShares(newShares);
            onHide(false);
        })
      }

    function removeShare(onHide, shareId) {
        fetch(`${api}/share/remove/${shareId}`, {
            shareId
        }).then(result => {
            if (result.status === 200) {
                var newShares = shares.filter(function(oldShares) { return oldShares.id !== shareId });
                setShares(newShares);
                onHide();
            } else {
                setIsShareError(true);
            }
        }).catch(e => {
            setIsShareError(true);
        });
    }

    return (
    <SharesContext.Provider value={{shares, communityShares, shareRequests, setShares, addShare, addShareRequest, editShare, removeShare, isShareError, addShareSuccess, setIsShareError, setAddShareSuccess}}>
        {children}
    </SharesContext.Provider>
    )
}