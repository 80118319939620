import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Container, Card, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import {Button, ButtonGroup, Badge, Avatar} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { usePost } from "../../context/posts";
import { timeSince } from "../../core/utils";
import { useConfig } from "../../context/config";
import { useUser } from "../../context/user";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PostMenu from './PostMenu';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { makeStyles } from '@material-ui/core/styles';
import ShareModal from '../Posts/ShareModal';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';

const styles = {
  Container: {
    marginTop: "2rem",
    paddingLeft: 0,
    paddingRight: 0,
  },
  MemberAvatar: {
    width: "3rem",
    height: "3rem",
    display: "inline-flex",
    verticalAlign: "top",
    marginRight: "2rem",
    border: "1px solid white",
    boxShadow: "4px 4px 10px rgba(50,50,50,0.5)",
  },
  Post: {
    background: "white",
    color: "black",
    borderRadius: "2px",
    marginBottom: "1rem",
    padding: "0px",
    boxShadow: "2px 2px 6px rgba(50,50,50,0.5)",
    width: "100%",
    maxWidth: "96vw",
  }
}

const useStyles = makeStyles((theme) => ({
  likesList: {
    listStyleType: 'none',
    textAlign: 'left',
    margin: 0,
    padding: 0,
  },
  commentInput: {
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: 'white',
  }
}));

const Posts = () => {

  const { addComment, posts, likePost, postsToLoad, setPostsToLoad } = usePost();
  const { images } = useConfig();
  const { user } = useUser();

  const [commentShow, setCommentShow] = useState({});
  const [newComment, setNewComment] = useState({});
  const [likes, setLikes] = useState({});

  const [shareModalShow, setShareModalShow] = React.useState({});

  const toggleShareModal = (gardenId) => () => setShareModalShow({
    ...shareModalShow,
    [gardenId]: !shareModalShow[gardenId]
  });

  const toggleCommentBox = (postId) => () => setCommentShow({
    ...commentShow,
    [postId]: !commentShow[postId]
  })

  const toggleLike = (postId) => setLikes({...likes, [postId]: !likes[postId]})

  const handleLike = (postId) => {
    // toggleLike(postId);
    likePost(postId, toggleLike);
  }

  const handlePost = (parentId) => {
    if(newComment !== "") {
      addComment(newComment[parentId], parentId);
      setNewComment({...newComment, [parentId]: ""});
    }
  }

  const handleCommentChange = (postId, value) => setNewComment({...newComment, [postId]: value})

  const PostCommentButton = ({parentId}) => {
    return(
      <>
      <ButtonGroup size="small" aria-label="small outlined button group">
        {/* <Button color="primary" variant="text"><FaCamera /></Button> */}
        <Button disabled={!user.public} variant="contained" color="primary" onClick={() => handlePost(parentId)}>Post</Button>
      </ButtonGroup>
      </>
  )}

  const personImage = (person) => {
    if (person.profilePhoto === "default.jpg" || person.profilePhoto === null) {
        return(`${images}/profiles/default-profile.jpg`);
    } else {
        return(`${images}/profiles/${person.id}/${person.profilePhoto}`);
    }
  }

  const classes = useStyles();

  return (
      <Container style={styles.Container}>
      {posts.length > 0 ?
        <>
        {posts.map((post) => (
          <React.Fragment key={post.id}>
            <Card style={styles.Post}>
                <Card.Header style={{display: "flex"}}>
                  <Avatar src={personImage(post.user)} />
                  <span style={{marginLeft: '1rem'}}><Link to={`/growver/${post.user.id}`}>{post.user.username}{post.gardenId !== null ? <>&apos;s</> : null }</Link></span>
                  {post.gardenId !== null ? 
                      <>&nbsp;&#183;&nbsp;<span><Link to={`/garden/${post.gardenId}`}>{post.gardenName}</Link></span></>
                  : null}
                  <div style={{flex: '1'}}></div>
                  <span style={{color:"grey"}}>{timeSince(post.created)}</span><br/><br/>
                  {post.user.id === user.id ? <PostMenu post={post} /> : null }
                </Card.Header>
                <Card.Body style={{padding: '0.5rem'}}>
                  <p>{post.message}</p>
                  {post.photo !== null ?
                    <>
                      <div loading="lazy" style={{borderRadius: '5px', zIndex: '1', margin: '0 auto', height: '100% !important', textAlign: "center", backgroundImage: `url(${images}/posts/${post.id}/${post.photo})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                          <LazyLoadImage
                            alt={post.photo.alt}
                            effect="blur"
                            src={`${images}/posts/${post.id}/${post.photo}`}
                            style={{margin: '0 auto', maxWidth: '100%', maxHeight: '600px', filter: 'saturate(120%)', zIndex: '999'}}
                          />
                      </div>
                    </>
                  :
                    null
                  }
                </Card.Body>
                {post.user.id !== 0 &&
                
                <Card.Footer>
                  <Button 
                    color="primary"
                    onClick={() => handleLike(post.id)}
                  >
                          {post.likes.map(function(e) { return e.userId; }).indexOf(user.id) > -1 ?
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><ul className={classes.likesList}>{post.likes.map((l) => (<li key={l.id}>{l.userName}</li>))}</ul></Tooltip>}>
                              <Badge badgeContent={post.likes.length} color="secondary">
                                <FavoriteIcon color="primary" size={24} />
                              </Badge>
                            </OverlayTrigger>
                          :
                            <OverlayTrigger overlay={
                              <Tooltip id="tooltip-disabled">{post.likes.length > 0 ? 
                                <span><ul className={classes.likesList}>{post.likes.map((l) => (<li key={l.id}>{l.userName}</li>))}</ul></span>
                                : 
                                <span>No one has liked this post yet</span>} 
                              </Tooltip>}>
                              <Badge badgeContent={post.likes.length} color="secondary">
                                <FavoriteBorderIcon color="primary" size={24} /> 
                                </Badge>
                            </OverlayTrigger>
                          }
                  </Button>
                  <Button variant="outlined" color="primary" onClick={toggleCommentBox(post.id)}>comments ({post.comments.length})</Button>
                  <IconButton aria-label="share this post"
                    onClick={toggleShareModal(post.id)}
                  >
                    <ShareIcon />
                  </IconButton>
                  {post.comments.map((c) => (
                    <Card key={c.id}>
                      <Card.Header style={{display: "flex"}}>
                        <Avatar src={personImage(c.user)} />
                        <span style={{marginLeft: '1rem'}}><Link to={`/growver/${c.user.id}`}>{c.user.username}</Link></span>
                        <div style={{flex: '1'}}></div>
                        <span style={{color:"grey"}}>{timeSince(c.created)}</span><br/><br/>
                        {c.user.id === user.id ? <PostMenu post={c} /> : null }
                      </Card.Header>
                      <Card.Body>
                          {c.message}
                      </Card.Body>
                    </Card>
                  ))}
                    <TextField
                      type="textarea"
                      multiline
                      fullWidth
                      disabled={!user.public}
                      className={classes.commentInput}
                      placeholder={user.public ? "Type your comment.." : "Make your profile public in your Account Settings if you would like to comment on this post."}
                      variant="outlined"
                      rowsMax={4}
                      value={newComment[post.id]}
                      onChange={(e) => handleCommentChange(post.id, e.target.value)}
                      id="post-comment"
                    /><br/>
                    <PostCommentButton parentId={post.id} />
                </Card.Footer>
              }
            </Card>
            <ShareModal
              show={shareModalShow[post.id]}
              post={post}
              onHide={toggleShareModal(post.id)}
            />
          </React.Fragment>
        )
        )}
        <div>
          {posts.length % 5 === 0 && posts.length > 4 &&
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={() => setPostsToLoad(postsToLoad+5)}
              >
              Load More
            </Button>
          }
        </div>
        </>
      :
        <div style={{textAlign: "center"}}>
          <Spinner animation="border" role="status" style={{color:"white", marginTop: "2rem"}}>
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      }
      </Container>
  );
}

export default React.memo(Posts);