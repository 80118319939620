import React, { useState } from "react";
// import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Row, Col, Container } from "react-bootstrap";
import { useFriends } from "../../context/friends";
import { MessageProvider } from "../../context/messages";
import { ChatModal } from "../Messages/Chat";
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MessageIcon from '@material-ui/icons/Message';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { Badge } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      marginBottom: '0.3rem',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    }
  }));

const personImage = (person) => {
    if (person.profilePhoto === "default.jpg" || person.profilePhoto === null) {
        return("https://growve-images.s3.amazonaws.com/profiles/default-profile.jpg");
    } else {
        return("https://growve-images.s3.amazonaws.com/profiles/" + person.id + "/" + person.profilePhoto);
    }
}

export const Friends = () => {

  const {friends, setFriends } = useFriends();
  
  const [messageModalShow, setMessageModalShow] = useState({});

  const toggleMessageModal = (friendId) => () => setMessageModalShow({
    ...messageModalShow,
    [friendId]: !messageModalShow[friendId]
  })

  function openConversation(friendId) {
    setMessageModalShow({
        ...messageModalShow,
        [friendId]: !messageModalShow[friendId]
        });
    let newFriends = friends;
    newFriends.find(f => f.id === friendId).numUnread = 0;
    setFriends(newFriends);
  }

  const classes = useStyles();

  return(
      <Container className={classes.list}>
          <Row>
              <Col sm>
                    {friends.map((friend) => (
                          <React.Fragment key={friend.id}>
                              <MessageProvider messageFriendId={friend.id}>
                              <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                      <Link to={`/growver/${friend.id}`}>
                                        <Avatar alt={friend.name} src={personImage(friend)}>
                                        </Avatar>
                                      </Link>
                                    }
                                    title={<Link to={`/growver/${friend.id}`}>{friend.name}</Link>}
                                    action={
                                        <Button style={{height: "60%", float: "right"}}
                                            onClick={() => openConversation(friend.id)}
                                        >
                                          <Badge badgeContent={friend.numUnread} color="secondary">
                                              <MessageIcon color="primary" />
                                          </Badge>
                                        </Button>
                                    }
                                />
                              </Card>
                              <ChatModal
                                  show={messageModalShow[friend.id]}
                                  friend={friend}
                                  onHide={toggleMessageModal(friend.id)}
                              />
                              </MessageProvider>
                          </React.Fragment>
                        )
                    )}
              </Col>
          </Row>
      </Container>
  )
}

export default Friends;