import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {useUser} from "../context/user";
import { useConfig } from './config';

export const PeopleContext = createContext();

export function usePeople() {
  return useContext(PeopleContext);
}

export const PeopleProvider = ({children}) => {
    const {api} = useConfig();

    const {user, refreshUser} = useUser();
    const [people, setPeople] = useState([]);
    const [allPeople, setAllPeople] = useState([]);

    const [person, setPerson] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        fetch(`${api}/people/${user.id}`).then(result =>
            result.json().then(data => {
                setPeople(data);
                refreshUser(user.id);
            })
        );

        fetch(`${api}/people/all`).then(result =>
            result.json().then(data => {
                setAllPeople(data);
                setIsLoading(false);
            })
        );
    // eslint-disable-next-line
    }, [user.id, api]);

    function refreshPeople() {
        fetch(`${api}/people/${user.id}`).then(result =>
            result.json().then(data => {
                setPeople(data);
            })
        );
    }

    const getPublicProfile = useCallback((userId) => {
        return fetch(`${api}/user/${user.id}/get/${userId}`).then((result) => {
            result.json().then(data => {
                setPerson(data);
            })
        }
        );
    }, [api, user.id])

    return (
    <PeopleContext.Provider value={{isLoading, people, setPeople, refreshPeople, allPeople, getPublicProfile, person}}>
        {children}
    </PeopleContext.Provider>
    )
}

export const withPeople = (Wrapped) => (props) => {
    return (
        <PeopleProvider>
            <Wrapped {...props} />
        </PeopleProvider>
    )
}