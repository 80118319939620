import React, { useRef, useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Rectangle } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import styled from "styled-components";

import {useUser} from "../context/user";

const Container = styled.div`
  width: 100%;
  height: 70%;
  min-height: 300px;
`;

const libraries = ["places"];

const mapContainerStyle = {
  height: "100%",
  maxHeight: "400px",
  width: "100%",
};

const communityMapContainerStyle = {
  height: "80%",
  maxHeight: "300px",
  width: "100%",
  border: "2px solid white",
  boxShadow: "4px 4px 10px rgba(50,50,50,0.5)",
};

const optionsCommunity = {
  disableDefaultUI: true,
  mapTypeId: "terrain",
  zoomControl: false,
};

const options = {
  mapTypeId: "terrain",
  zoomControl: true,
};

const rectangleOptions = {
  fillColor: 'skyblue',
  fillOpacity: 0.3,
  strokeColor: 'rgb(0,100,100)',
  strokeWeight: 0.8
}

export const CommunityMap = (props) => {

  let {user} = useUser();

  if (props.user) {
    user = props.user;
  }

  const initialRectangleBounds = {
    north: user.boundsN,
    south: user.boundsS,
    east: user.boundsE,
    west: user.boundsW
  };
  
  const center = {
    lat: (initialRectangleBounds.north + initialRectangleBounds.south) / 2,
    lng: (initialRectangleBounds.west + initialRectangleBounds.east) /2,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <>
      <GoogleMap
        id="map"
        mapContainerStyle={communityMapContainerStyle}
        zoom={10}
        center={center}
        options={optionsCommunity}
        onLoad={onMapLoad}
      >
        <Rectangle
          bounds={initialRectangleBounds}
          draggable={false}
          editable={false}
          options={rectangleOptions}
        />
      </GoogleMap>
    </>
  );
}

export function MyMaps() {
  const { user, setUser } = useUser();

  const centerLat = (user.boundsN + user.boundsS) / 2;
  const centerLng = (user.boundsE + user.boundsW) / 2;

  const initialRectangleBounds = {
    north: user.boundsN || -27.4698,
    south: user.boundsS || -27.4608,
    east: user.boundsE || 153.0251,
    west: user.boundsW || 153.0151
  };
  
  const initialCenter = {
    lat: (centerLat || user.addressLat || 0),
    lng: (centerLng || user.addressLng || 0)
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  
  const [rectangleBounds, setRectangleBounds] = React.useState(initialRectangleBounds);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setUser((user) => ({
        ...user,
        boundsN: rectRef.current.state.rectangle.getBounds().getNorthEast().lat(),
        boundsE: rectRef.current.state.rectangle.getBounds().getNorthEast().lng(),
        boundsS: rectRef.current.state.rectangle.getBounds().getSouthWest().lat(),
        boundsW: rectRef.current.state.rectangle.getBounds().getSouthWest().lng()
      }));
    }, 2000)
    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [rectangleBounds, user, setUser])

  const onMapClick = React.useCallback((e) => {
    setRectangleBounds({
      north: e.latLng.lat() < 0 ? e.latLng.lat() + 0.5 : e.latLng.lat() - 0.5,
      south: e.latLng.lat() < 0 ? e.latLng.lat() - 0.5 : e.latLng.lat() + 0.5,
      east: e.latLng.lng() + 0.5,
      west: e.latLng.lng() - 0.5
    })
  }, []);

  const mapRef = useRef();

  const rectRef = useRef();
  const onRectangleBoundsChanged = useCallback(() => {
    if (rectRef.current) {
      setRectangleBounds(rectRef.current.state.rectangle.getBounds());
    }
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  

  return (
    <Container>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={initialCenter}
        options={options}
        onClick={onMapClick}
        ref={mapRef}
      >
        <Rectangle
          bounds={rectangleBounds}
          onBoundsChanged={onRectangleBoundsChanged}
          ref={rectRef}
          draggable={true}
          editable={true}
          options={rectangleOptions}
        />
      </GoogleMap>
    </Container>
  );
}