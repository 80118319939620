import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserProvider } from './context/user';
import { AuthProvider } from './context/auth';
import { ConfigProvider } from './context/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';

if (process.env.NODE_ENV === "production") {
    hotjar.initialize(2305957, 6);
    const tagManagerArgs = {
        gtmId: 'GTM-PWRRKNX'
    }
    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
    <ConfigProvider>
    <Helmet>
        <title>Growve - Gardening Community</title>
        <meta name="description" content="Gardening Community, Tool & Produce Sharing" />
    </Helmet>
        <AuthProvider>
            <UserProvider>
                <App />
            </UserProvider>
        </AuthProvider>
    </ConfigProvider>
    , document.getElementById('root')
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
