import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useUser} from './user';

export const PlantsContext = createContext();

export function usePlants() {
  return useContext(PlantsContext);
}

export const PlantsProvider = ({children}) => {
    const [api] = useState(process.env.NODE_ENV === "production" ? "https://api.growve.co" : "http://localhost:5000");

    const [plants, setPlants] = useState([]);
    const [tradeablePlants, setTradeablePlants] = useState([]);
    const {user} = useUser();
    const userId = user.id;

    const [isPlantsError, setIsPlantsError] = useState(false);
    const [addPlantSuccess, setAddPlantSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/plants`, {userId}).then(result =>
            result.json().then(data => {
                setPlants(data.plants);
            })
        );
    }, [userId, api]);

    useEffect(() => {

        fetch(`${api}/plants/tradeable`).then(result =>
        result.json().then(data => {
            setTradeablePlants(data);
        })
        );
    }, [api]);


    function editPlant(cb = false, data) {
        axios.post(`${api}/plant/${data.newPlantId}/edit/`, {...data})
        .then((result) => {
            let newPlants = [...plants];
            if (plants.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newPlants = [result.data, ...plants];
            } else {
                newPlants[plants.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setPlants(newPlants);
            cb(false);
        })
    }


    function addPlant(newPlant) {
        return axios.post(`${api}/add_plant`, {
            ...newPlant,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsPlantsError(false);
                setAddPlantSuccess(true);
                setPlants([result.data, ...plants]);
                setTimeout(() => {
                    setAddPlantSuccess(false);
                }, 5000);
                return(result.data.id);
            } else {
                setIsPlantsError(true);
            }
        }).catch(e => {
            setIsPlantsError(true);
        });
    }


    function removePlant(cb, plantId) {
        fetch(`${api}/user/${userId}/remove_plant/${plantId}`, {
            plantId,
            userId
        }).then(result => {
            if (result.status === 200) {
                var newPlants = plants.filter(function(oldPlants) { return oldPlants.id !== plantId });
                setPlants(newPlants);
                cb(false);
            } else {
                setIsPlantsError(true);
            }
        }).catch(e => {
            setIsPlantsError(true);
        });
    }


    function givePlant(cb, plantId, newUserId) {
        plantId = cb.plantId;
        axios.post(`${api}/plant/${plantId}/swap/${newUserId}`, {
            plantId,
            newUserId
        }).then(result => {
            if (result.status === 200) {
                var newPlants = plants.filter(function(oldPlants) { return oldPlants.id !== plantId });
                setPlants(newPlants);
                cb(false);
            } else {
                setIsPlantsError(true);
            }
        }).catch(e => {
            setIsPlantsError(true);
        });
    }

    return (
    <PlantsContext.Provider value={{plants, tradeablePlants, addPlant, givePlant, setPlants, editPlant, removePlant, isPlantsError, addPlantSuccess, setIsPlantsError, setAddPlantSuccess}}>
        {children}
    </PlantsContext.Provider>
    )
}