import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import { useUser } from '../../context/user';
import { useConfig } from '../../context/config';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { useFriends } from '../../context/friends';
import { IconButton, Tooltip, Zoom, Typography, Divider } from '@material-ui/core';

export default function NotificationsMenu() {
  const { user } = useUser();
  const { images } = useConfig();
  const { acceptRequest, ignoreRequest } = useFriends();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const personImage = (person) => {
    if (person.profilePhoto === "default.jpg" || person.profilePhoto === null) {
        return(`${images}/profiles/default-profile.jpg`);
    } else {
        return(`${images}/profiles/${person.id}/${person.profilePhoto}`);
    }
  }

  return (
    <div>
      {user.friendsPending ?
      <>
      <div aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {user.friendsPending ?
          <IconButton>
            <Tooltip title={<Typography color="inherit">Notifications</Typography>} arrow TransitionComponent={Zoom} aria-label="Notifications">
              <Badge badgeContent={user.friendsPending.length} color="secondary">
                <NotificationsIcon style={{color: "white", cursor: 'pointer'}} />
              </Badge>
            </Tooltip>
          </IconButton>
        :
          <Tooltip title={<Typography color="inherit">Notifications</Typography>} arrow TransitionComponent={Zoom} aria-label="Notifications">
            <NotificationsIcon style={{color: "white", marginLeft: '.5rem', marginRight: '.5rem'}} />
          </Tooltip>
        }
      </div>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{marginTop: '2rem'}}
      >
        <div style={{marginLeft: '1rem', textAlign: 'left', minWidth: '300px'}}><h5>Notifications</h5></div>
        <Divider />
        {user.friendsPending.length > 0 ? 
          <Typography variant="caption" gutterBottom style={{marginLeft: "1rem"}}>
            Friend Requests
          </Typography> 
        :
          <Typography variant="caption" align="center" gutterBottom style={{margin: '0 auto', marginLeft: "1rem"}}>
            Nothing to report
          </Typography>
        }
        {user.friendsPending.map((friendRequest) => (
            <MenuItem key={friendRequest.id} style={{cursor: 'pointer !important'}} onClick={handleClose}>
            
              <Avatar src={personImage(friendRequest)} component={Link} to={`/growver/${friendRequest.id}`} />

              &nbsp;<span component={Link} to={`/growver/${friendRequest.id}`}>{friendRequest.username}</span>&nbsp;
              <div style={{flexGrow: '1'}}></div>
              <Button 
                variant='contained'
                color='primary'
                onClick={() => acceptRequest(friendRequest.id)}
              >
                <FaThumbsUp />
              </Button>&nbsp;

                <Button 
                  variant='contained'
                  onClick={() => ignoreRequest(friendRequest.id)}
                >
                  <FaThumbsDown />
                </Button>

              </MenuItem>
          ))}
          </Menu>
        </>
        :
            null
        }
    </div>
  );
}
