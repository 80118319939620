import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {FaCompass, FaHome, FaListAlt, FaTree} from "react-icons/fa";
import MoreMenu from './MoreMenu';
import { useUser } from '../../context/user';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '100%',
    position: "fixed",
    bottom: '0',
    borderTop: "1px solid grey !important",
    zIndex: "999",
  },
  actions: {
    minWidth: '50px',
    color: 'rgb(100,100,100)',
    fontWeight: '550',
    '&:hover': {
      textDecoration: 'none',
    }
  }
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const { mobileIndex, setMobileIndex } = useUser();

  return (
    <BottomNavigation
      value={mobileIndex}
      onChange={(event, newValue) => {
        setMobileIndex(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction value="dashboard" className={classes.actions} component={Link} to="/dashboard" label="Home" icon={<FaHome size={24}/>} />
      <BottomNavigationAction value="community" className={classes.actions} component={Link} to="/community" label="Community" icon={<FaListAlt size={24} />} />
      <BottomNavigationAction value="gardens" className={classes.actions} component={Link} to="/gardens" label={<span style={{}}>My&nbsp;Gardens</span>} icon={<FaTree size={24} />} />
      <BottomNavigationAction value="explore" className={classes.actions} component={Link} to="/explore" label="Explore" icon={<FaCompass size={24}/>} />
      <MoreMenu className={classes.actions} />
    </BottomNavigation>
  );
}