import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const FriendsContext = createContext();

export function useFriends() {
  return useContext(FriendsContext);
}

export const FriendsProvider = ({children}) => {

    const {api} = useConfig();
    const [friends, setFriends] = useState([]);
    // const [friendsUpdates, setFriendsUpdates] = useState([]);
    const { isLoggedIn, user, refreshUser} = useUser();
    const userId = user.id;

    const [isFriendsError, setIsFriendsError] = useState(false);
    const [addFriendSuccess, setAddFriendSuccess] = useState("");
    const [friendRequestSent, setFriendRequestSent] = useState(false);

    useEffect(() => {

        isLoggedIn && 

            fetch(`${api}/user/${userId}/friends`, {userId}).then(result =>
                result.json().then(data => {
                    data = data.sort((a, b) => {
                        return b.lastMessage - a.lastMessage;
                    });
                    setFriends(data);
                })
            );
            setInterval(() => {
                fetch(`${api}/user/${userId}/friends`, {userId}).then(result =>
                    result.json().then(data => {
                        data = data.sort((a, b) => {
                            return b.lastMessage - a.lastMessage;
                        });
                        setFriends(data);
                    })
                );
            }, 60000);

    }, [userId, api, isLoggedIn]);

    function friendRequest(friendId, message) {
        axios.post(`${api}/friend/request`, {
            friendId,
            userId,
            message
        }).then(result => {
            if (result.status === 200) {
                setFriendRequestSent(true);
            } else {
                setIsFriendsError(true);
                setAddFriendSuccess(false);
            }
        }).catch(e => {
            console.log(e);
            setIsFriendsError(true);
        });
    }

    function acceptRequest(friendId) {
        axios.post(`${api}/friend/accept`, {
            userId: friendId,
            friendId: userId
        }).then(result => {
            if (result.status === 200) {
                setIsFriendsError(false);
                setAddFriendSuccess(true);
                let newFriends = [...friends];
                newFriends[friends.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                setFriends(newFriends);
                refreshUser(userId);
                setTimeout(() => {
                    setAddFriendSuccess(false);
                }, 5000);
            } else {
                setIsFriendsError(true);
                setAddFriendSuccess(false);
            }
        }).catch(e => {
            console.log(e);
        })
    }

    function ignoreRequest(friendId) {
        axios.post(`${api}/friend/ignore`, {
            userId: friendId,
            friendId: userId
        }).then(result => {
            if (result.status === 200) {
                refreshUser(userId);
            } else {
                console.log("error");
            }
        }).catch(e => {
            console.log(e);
        })
    }

    function removeFriend(friendId) {
        fetch(`${api}/user/${userId}/remove_friend/` + friendId, {
            friendId,
            userId
        }).then(result => {
            if (result.status === 200) {
                var newFriends = friends.filter(function(oldFriends) { return oldFriends.id !== friendId });
                setFriends(newFriends);
            } else {
                setIsFriendsError(true);
            }
        }).catch(e => {
            setIsFriendsError(true);
            console.log(e);
        });
    }

    return (
    <FriendsContext.Provider value={{friends, setFriends, friendRequest, acceptRequest, ignoreRequest, removeFriend, isFriendsError, addFriendSuccess, friendRequestSent}}>
        {children}
    </FriendsContext.Provider>
    )
}