import React from "react";
import { Link, Redirect } from "react-router-dom";
import { FaUsers, FaTools, FaSeedling } from "react-icons/fa";
import { GiPlantsAndAnimals } from "react-icons/gi";
import { Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

import { CommunityMap } from "../core/maps";
import { useUser } from "../context/user";

import './Community.css';
import { Grid, Card, Typography } from "@material-ui/core";
import { useWindowDimensions } from "../context/config";

export const CommunityInfo = () => {

  const {user} = useUser();
  const {width} = useWindowDimensions();

  if (width > 600) {
    return(<Redirect to="/community"/>)
  } else {
    return (
      <Grid container>
        <Grid item style={{color: 'whitesmoke', margin: '0 auto'}}>
          <Typography variant="h5">Community Info</Typography>
        </Grid>
        <Grid style={{width: '100%', margin: '0 auto'}}>
          <Card style={{minHeight: '600px', height: "80%", color: "black", width: "100% !important", padding: "10px", textAlign: 'center'}}>
          <p>
          <Button variant="outlined" as={Link} to="/community/members">
            Members <FaUsers size={24} data-tip="Community Members" className="communityIcons"/>:
          </Button>{user.community.people}<br/>
          Tools <FaTools size={24} data-tip="Available Tools" className="communityIcons"/>: {user.community.tools}<br/>
          Gardens <GiPlantsAndAnimals size={24} data-tip="Member Gardens" className="communityIcons"/>: {user.community.gardens}<br/>
          Plants <FaSeedling size={24} data-tip="Member Plants" className="communityIcons"/>: {user.community.plants}<br/>
          </p>
          <ReactTooltip/>
            <Link to="/resources">
              <Button variant="primary">Search Resources</Button>
            </Link><br/><br/>
          <h5>Your map</h5>
          <p>Change this in your account page</p>
          <CommunityMap /><br/>
          </Card>
        </Grid>
      </Grid>
      )
  }
}

export default CommunityInfo;