import React, { useState, lazy, Suspense } from 'react';
import styled from 'styled-components';
import { Route, Switch, Link } from "react-router-dom";
import ScrollToTop from '../core/ScrollToTop';
import PrivateRoute from '../PrivateRoute';

import {useWindowDimensions} from "../context/config";
import {useUser} from "../context/user";

import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import logoImg from "../img/growve_logo.svg";
import { FaChevronDown, FaChevronUp, FaCogs, FaCompass, FaHandHoldingHeart, FaHome, FaListAlt, FaRegQuestionCircle, FaSearch, FaSeedling, FaTools, FaTree, FaQuestionCircle } from 'react-icons/fa';
// import { MdDashboard } from 'react-icons/md';
import { RiScissorsFill } from "react-icons/ri";
import { IoReader } from "react-icons/io5";
import { GiEggClutch, GiToolbox, GiWoodenFence, GiThreeFriends } from 'react-icons/gi';
// import { SiWish } from 'react-icons/si';
// import { Avatar } from '@material-ui/core';
// import Asynchronous from '../core/Search';
import AddShare from "../components/Share/AddShare";
import ProfileMenu from "../components/Account/ProfileMenu";
import NotificationsMenu from "../components/Account/NotificationsMenu";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import VerifyModal from "../components/Account/VerifyModal";
import FriendsDrawer from '../components/Friends/FriendsDrawer';
import FeedbackForm from '../components/Account/FeedbackForm';
import CommunityInfo from './CommunityInfo';
import BottomNav from '../components/Account/BottomNav';
import Community from './Community';
// const Community = lazy(() => import('./Community'));
// import VerifyButton from '../components/Account/VerifyButton';

import Home from './Home';
import { MdAccountCircle } from 'react-icons/md';


const HowItWorks = lazy(() => import('./HowItWorks'));
const FAQ = lazy(() => import('./FAQ'));
const ContactUs = lazy(() => import('./ContactUs'));
const Mission = lazy(() => import('./Mission'));
const Blog = lazy(() => import('./Blog'));
const Blogs = lazy(() => import('./Blogs'));
const Dashboard = lazy(() => import('./Dashboard'));
const Account = lazy(() => import('./Account'));
const Resources = lazy(() => import('./Resources'));
const Login = lazy(() => import('./Login'));
const Signup = lazy(() => import('./Signup'));
const PasswordReset = lazy(() => import('./PasswordReset'));
const Members = lazy(() => import('./Members'));
const Gardens = lazy(() => import('./Gardens'));
const Garden = lazy(() => import('./Garden'));
const Profile = lazy(() => import('./Profile'));
const Growver = lazy(() => import('./Growver'));
const Requests = lazy(() => import('./Requests'));
const Shares = lazy(() => import('./Shares'));
const SearchShares = lazy(() => import('./SearchShares'));
const AssetsPlants = lazy(() => import('./Plants'));
const AssetsTools = lazy(() => import('./Tools'));
const AssetsSeeds = lazy(() => import('./Seeds'));
const AssetsCuttings = lazy(() => import('./Cuttings'));
// const Wishes = lazy(() => import('./Wishes'));
const AssetsHardscaping = lazy(() => import('../pages/Hardscaping'));
const Explore = lazy(() => import('../pages/Explore'));
// const ExploreGardens = lazy(() => import('./EGardens'));
// const ExploreGardeners = lazy(() => import('./Gardeners'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
// import Footer from "./Footer";
const FriendsPage = lazy(() => import('./Friends'));
const Post = lazy(() => import('./Post'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 9999999,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 16,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#383838 !important',
    color: 'whitesmoke',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },
  drawerClose: {
    color: 'whitesmoke',
    backgroundColor: '#383838 !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerList: {
    '&:hover': {
      color: 'lightgrey',
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(16),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      color: 'lightgrey',
    }
  },
  divider: {
    margin: '0.2rem',
  },
  active: {
    backgroundColor: 'green !important',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    padding: 3,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'grey'
  },
  inputRoot: {
    color: 'grey',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  button: {
    margin: theme.spacing(1),
    fontWeight: '500',
    color: 'white',
    border: '1px solid white',
    backgroundColor: 'rgba(0,123,255,0)',
    '&:hover': {
      backgroundColor: 'rgba(0,123,255,1)',
      color: 'white !important',
    }
  },
  signupButton: {
    backgroundColor: '#007bff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#006bff',
      color: 'white !important',
    }
  }
}));

const Container = styled.div`
    background: radial-gradient(122.97% 106.94% at 50% 0%, #2EB000 0%, #0B6A28 39.68%, #17514B 100%);
    align-content: center;
    vertical-align: baseline;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    height: auto;
    align-content: left;
    z-index: 3;
    overflow-y: hidden;
`;

const Logo = styled.img`
    height:100%;
    max-height: 35px;
    max-width: 100px;
    transition: max-width 2s;
`;

function Body() {

    const {width} = useWindowDimensions();
    const {isLoggedIn, user, selectedIndex, setSelectedIndex} = useUser();

    const [open, setOpen] = useState(isLoggedIn && width > 1000);
    const [resourcesOpen, setResourcesOpen] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false);
    const [errorSnack, setErrorSnackOpen] = useState(false);
    const [feedbackSnack, setFeedbackSnack] = useState(false);

    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackOpen(false);
      setErrorSnackOpen(false);
      setFeedbackSnack(false);
    };

    const classes = useStyles();
    const theme = useTheme();
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const handleResourceClick = () => {
      setResourcesOpen(!resourcesOpen);
    };


    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };

    const [addShareModalShow, setAddShareModalShow] = React.useState({});

    const newShare = 9999999;
  
    const toggleAddShareModal = (newShare) => () => setAddShareModalShow({
      ...addShareModalShow,
      [newShare]: !addShareModalShow[newShare]
    });

    return(
        <Container>
        <div className={classes.root}>
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
            })}
        >
        <Toolbar style={{backgroundColor: "green"}}>
            { isLoggedIn && width > 600 ?
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
            :
                null
            }
            <Logo src={logoImg} />&nbsp;
            <Typography variant="h6" style={{marginRight: "0.5rem"}}>
              <IconButton style={{backgroundColor: 'rgba(255,255,255,0)', textDecoration: 'none', fontWeight: 'bold', fontSize: "1rem", color: "white", cursor: 'pointer'}} aria-label="home" color="inherit" component={Link} to={isLoggedIn ? `/dashboard` : `/`}>
                GROWVE
              </IconButton>
            </Typography>
            
            { width > 600 ?
                <>
                  { isLoggedIn ?
                    <>
                      <IconButton style={{fontSize: "1rem", color: "white", backgroundColor: 'rgba(255,255,255,0)', border: '0'}} aria-label="blogs" color="inherit" component={Link} to={`/blogs`}
                        onClick={(event) => {handleListItemClick(event, 999)}}
                      >
                          <IoReader size={24} />&nbsp;Blogs
                      </IconButton>

                      <IconButton style={{fontSize: "1rem", color: "white", backgroundColor: 'rgba(255,255,255,0)'}} aria-label="gardens" color="inherit" component={Link} to={`/explore`}
                        onClick={(event) => {handleListItemClick(event, 999)}}
                      >
                          <FaCompass size={24} />&nbsp;Explore
                      </IconButton>

                      <IconButton style={{fontSize: "1rem", color: "white", backgroundColor: 'rgba(255,255,255,0)'}}
                        onClick={toggleAddShareModal(newShare)}
                      >
                        <FaHandHoldingHeart color="white" size={24}/>&nbsp;
                        Share
                      </IconButton>
                      <AddShare
                          show={addShareModalShow[newShare]}
                          onHide={toggleAddShareModal(newShare)}
                      />

                      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                          Account Verified!
                        </Alert>
                      </Snackbar>
                      <Snackbar open={errorSnack} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                          Account Verification Failed
                        </Alert>
                      </Snackbar>
                      <Snackbar open={feedbackSnack} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="info">
                          Help is on the way! We'll get you verified asap.
                        </Alert>
                      </Snackbar>
                      {/* <Tour 
                        isOpen={isTourOpen}
                        closeTour={() => setIsTourOpen(false)}
                      /> */}
                      {user.verified !== true ?
                        <>
                          <Button variant="contained" color="primary" onClick={() => setShowVerifyModal(true)}>{width > 1200 ? <>Verify Your Account</> : <>Verify</>}</Button>
                          <VerifyModal
                            show={showVerifyModal}
                            onHide={() => setShowVerifyModal(false)}
                            errorSnack={() => setErrorSnackOpen(true)}
                            successSnack={() => setSnackOpen(true)}
                            feedbackSnack={() => setFeedbackSnack(true)}
                          />
                          <br/>
                        </>
                      :
                        null
                      }

                      <div style={{flexGrow: '1'}}></div>
                      <FeedbackForm />
                      <FriendsDrawer />
                      <NotificationsMenu />
                      <ProfileMenu />
                    </>
                  :
                  <>
                    <IconButton style={{backgroundColor: 'rgba(255,255,255,0)', fontWeight: 'bold', fontSize: "1rem", color: "white"}} aria-label="gardens" color="inherit" component={Link} to={`/explore`}
                      onClick={(event) => {handleListItemClick(event, 999)}}
                    >
                      <FaCompass size={24} />&nbsp;Explore
                    </IconButton>
                    <IconButton style={{backgroundColor: 'rgba(255,255,255,0)', fontWeight: 'bold', fontSize: "1rem", color: "white"}} aria-label="how it works" color="inherit" component={Link} to={`/how-it-works`}
                      onClick={(event) => {handleListItemClick(event, 999)}}
                    >
                      <FaCogs size={24} />&nbsp;How It Works
                    </IconButton>
                    <IconButton style={{backgroundColor: 'rgba(255,255,255,0)', fontWeight: 'bold', fontSize: "1rem", color: "white"}} aria-label="FAQs" color="inherit" component={Link} to={`/faq`}
                      onClick={(event) => {handleListItemClick(event, 999)}}
                    >
                      <FaQuestionCircle size={24} />&nbsp;FAQs
                    </IconButton>
                    <div aria-label="spacer" style={{flexGrow: '1'}}>
                    </div>
                    <Button className={classes.button} size="large" variant="outlined" aria-label="login" component={Link} to="/login">
                        Login&nbsp;
                    </Button>
                    <Button className={classes.signupButton} size="large" variant="contained" aria-label="signup" component={Link} to="/signup">
                        Sign-Up&nbsp;
                    </Button>
                  </>
                  }
                </>
            :
              <>
                { isLoggedIn ?
                  <>
                  <div style={{flexGrow: 1}}></div>
                    <FeedbackForm />
                    <FriendsDrawer />
                    <NotificationsMenu />
                    <ProfileMenu />
                  </>
                :
                  <>
                  <div aria-label="spacer" style={{flexGrow: '1'}}>
                  </div>
                  <Button className={classes.button} size="large" variant="contained" aria-label="login" component={Link} to="/login">
                        Login&nbsp;
                    </Button>
                    <Button className={classes.signupButton} size="large" variant="contained" aria-label="signup" component={Link} to="/signup">
                        Signup&nbsp;
                    </Button>
                  </>
                }
              </>
            }
        </Toolbar>
      </AppBar>
      { isLoggedIn && width > 600 ?
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon style={{color: "whitesmoke"}} /> : <ChevronLeftIcon style={{color: "whitesmoke"}} />}
                </IconButton>
            </div>
            {/* <Divider className={classes.divider} /> */}
            <List>
                <ListItem 
                  selected={selectedIndex === 0} 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  component={Link} 
                  button 
                  to={'/dashboard'} 
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <FaHome color="white" size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  selected={selectedIndex === 1} 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  component={Link} 
                  button 
                  to={'/account'} 
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    {/* <Avatar style={{border: "2px solid skyblue"}} alt={user.name} src={personImage(user)}></Avatar> */}
                    <MdAccountCircle color="white" size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Account Settings" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  selected={selectedIndex === 15} 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  component={Link} 
                  button 
                  to={'/community'} 
                  onClick={(event) => handleListItemClick(event, 15)}
                >
                  <ListItemIcon>
                    {/* <Avatar style={{border: "2px solid skyblue"}} alt={user.name} src={personImage(user)}></Avatar> */}
                    <FaListAlt color="white" size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Community" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  selected={selectedIndex === 2} 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  component={Link} 
                  button 
                  to={'/gardens'} 
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemIcon><FaTree color="white" size={24} /></ListItemIcon>
                  <ListItemText primary="My Gardens" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  button 
                  onClick={handleResourceClick}
                >
                  <ListItemIcon><GiToolbox color="white" size={24} /></ListItemIcon>
                  <ListItemText primary="My Stuff" />&nbsp;&nbsp;
                  {resourcesOpen ? 
                    <ListItemIcon>&nbsp;&nbsp;<FaChevronUp color="white" size={20} /></ListItemIcon>
                  :
                    <ListItemIcon>&nbsp;&nbsp;<FaChevronDown color="white" size={20} /></ListItemIcon>
                  }
                </ListItem>

                <Collapse in={resourcesOpen} timeout="auto" unmountOnExit style={{backgroundColor: '#3f3f3f'}}>
                    <List disablePadding>
                      <ListItem 
                        button
                        selected={selectedIndex === 3} 
                        className={classes.nested} 
                        classes={{ selected: classes.active}} 
                        component={Link} 
                        to={'/resources/plants'}
                        onClick={(event) => {handleListItemClick(event, 3)}}
                      >
                        <ListItemIcon>
                        <FaSeedling color="#a6a6a6" size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Plants" />
                      </ListItem>

                      <ListItem 
                        button
                        selected={selectedIndex === 4} 
                        className={classes.nested} 
                        classes={{ selected: classes.active}} 
                        component={Link} 
                        to={'/resources/cuttings'}
                        onClick={(event) => {handleListItemClick(event, 4)}}
                      >
                        <ListItemIcon>
                        <RiScissorsFill color="#a6a6a6" size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Cuttings" />
                      </ListItem>

                      <ListItem 
                        button 
                        className={classes.nested} 
                        component={Link} 
                        to={'/resources/tools'}
                        onClick={(event) => {handleListItemClick(event, 5)}}
                        selected={selectedIndex === 5} 
                        classes={{ selected: classes.active}} 
                      >
                        <ListItemIcon>
                        <FaTools color="#a6a6a6" size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Tools" />
                      </ListItem>

                      <ListItem 
                        button 
                        className={classes.nested} 
                        component={Link} 
                        to={'/resources/seeds'}
                        onClick={(event) => {handleListItemClick(event, 6)}}
                        selected={selectedIndex === 6} 
                        classes={{ selected: classes.active}} 
                      >
                        <ListItemIcon>
                        <GiEggClutch color="#a6a6a6" size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Seeds" />
                      </ListItem>

                      <ListItem 
                        button 
                        className={classes.nested} 
                        component={Link} 
                        to={'/resources/materials'}
                        onClick={(event) => {handleListItemClick(event, 7)}}
                        selected={selectedIndex === 7} 
                        classes={{ selected: classes.active}} 
                      >
                        <ListItemIcon>
                        <GiWoodenFence color="#a6a6a6" size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Materials" />
                      </ListItem>
                    </List>
                </Collapse>

                <Divider className={classes.divider} />

                <ListItem 
                  button
                  selected={selectedIndex === 12} 
                  className={classes.drawerList} 
                  classes={{ selected: classes.active}} 
                  component={Link} 
                  to={'/resources'}
                  onClick={(event) => {handleListItemClick(event, 12)}}
                >
                  <ListItemIcon>
                  <FaSearch color="#a6a6a6" size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Resource Search" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  button 
                  className={classes.drawerList} 
                  component={Link} 
                  to={'/friends'}
                  onClick={(event) => {handleListItemClick(event, 8)}}
                  selected={selectedIndex === 8} 
                  classes={{ selected: classes.active}} 
                >
                  <ListItemIcon><GiThreeFriends color="white" size={24} /></ListItemIcon>
                  <ListItemText primary="Friends" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  button 
                  className={classes.drawerList} 
                  component={Link} 
                  to={'/requests'}
                  onClick={(event) => {handleListItemClick(event, 9)}}
                  selected={selectedIndex === 9} 
                  classes={{ selected: classes.active}} 
                >
                  <ListItemIcon><FaRegQuestionCircle color="white" size={24} /></ListItemIcon>
                  <Badge badgeContent={user.requestsWaiting} color="secondary">
                    <ListItemText primary="Requests" />
                  </Badge>
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem 
                  button 
                  className={classes.drawerList} 
                  component={Link} 
                  to={'/shares'}
                  onClick={(event) => {handleListItemClick(event, 10)}}
                  selected={selectedIndex === 10} 
                  classes={{ selected: classes.active}} 
                >
                  <ListItemIcon><FaHandHoldingHeart color="white" size={24} /></ListItemIcon>
                  <ListItemText primary="Shares" />
                </ListItem>

                {/* <Divider className={classes.divider} />

                <ListItem 
                  button 
                  className={classes.drawerList} 
                  component={Link} 
                  to={'/wishes'}
                  onClick={(event) => {handleListItemClick(event, 11)}}
                  selected={selectedIndex === 11} 
                  classes={{ selected: classes.active}} 
                >
                  <ListItemIcon><SiWish color="white" size={24} /></ListItemIcon>
                  <ListItemText primary="Wishes" />
                </ListItem> */}
            </List>
        </Drawer>
      :
        <>{isLoggedIn && 
          <BottomNav />
         }</>
      }

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ScrollToTop />
        <Suspense fallback={
          <div style={{placeContent: 'center'}}>
            <Backdrop open={true} animation="border" role="status" style={{color: "white"}}>
                <CircularProgress size={32} />
            </Backdrop>
          </div>
        }>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/community" component={Community} />
              <PrivateRoute exact path="/community/info" component={CommunityInfo} />
              <PrivateRoute exact path="/community/members" component={Members} />
              <Route exact path="/signup/:refId" component={Signup} />
              <Route exact path="/reset" component={PasswordReset} />
              <Route exact path="/contact" component={ContactUs} />
              <Route exact path="/mission" component={Mission} />
              <Route exact path="/blogs" component={Blogs} />
              <Route exact path="/blog/:slug" component={Blog} />
              <Route exact path="/how-it-works" component={HowItWorks} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/explore" component={Explore} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              {/* <Route exact path="/explore/gardens" component={ExploreGardens} /> */}
              {/* <Route exact path="/explore/gardeners" component={ExploreGardeners} /> */}
              <PrivateRoute path="/garden/:gardenId" component={Garden} />
              <Route path="/post/:postId" component={Post} />
              <PrivateRoute exact path="/resources" component={Resources} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/gardens" component={Gardens} />
              <PrivateRoute path="/friends" component={FriendsPage} />
              <PrivateRoute path="/requests" component={Requests} />
              <PrivateRoute path="/shares" component={Shares} />
              <PrivateRoute path="/searchshares" component={SearchShares} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/growver/:userId" component={Growver} />
              <PrivateRoute exact path="/resources/plants" component={AssetsPlants} />
              <PrivateRoute exact path="/resources/cuttings" component={AssetsCuttings} />
              <PrivateRoute exact path="/resources/tools" component={AssetsTools} />
              <PrivateRoute exact path="/resources/seeds" component={AssetsSeeds} />
              <PrivateRoute exact path="/resources/materials" component={AssetsHardscaping} />
              {/* <PrivateRoute exact path="/wishes" component={Wishes} /> */}
              <Route component={Home} />
          </Switch>
        </Suspense>
      </main>
    </div>
        </Container>
    )
}

export default Body;