import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useUser } from '../../context/user';
import { Avatar, Badge } from '@material-ui/core';
import { useConfig } from '../../context/config';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: 'red',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const ProfileHeader = styled.span`
  vertical-align: middle;
`;

export default function SimpleMenu() {
  const { user, logOut, setSelectedIndex } = useUser();
  const { images } = useConfig();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const personImage = (person) => {
    if (person.profilePhoto === "default.jpg" || person.profilePhoto === null) {
        return(`${images}/profiles/default-profile.jpg`);
    } else {
        return(`${images}/profiles/${person.id}/${person.profilePhoto}`);
    }
  }

  const classes = useStyles();

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          invisible={user.verified}
        >
          <Avatar style={{border: "2px solid white"}} alt={user.name} src={personImage(user)}></Avatar>
        </StyledBadge>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ProfileHeader>
          <Avatar className={classes.large} style={{display: 'inline-flex', margin: '.5rem'}} alt={user.name} src={personImage(user)} />
          <span style={{verticalAlign: 'top', margin: '1rem'}}>
            {user.name}
          </span>
        </ProfileHeader>          
        <hr/>
        <MenuItem onClick={handleClose} component={Link} to={`/growver/${user.id}`}>Profile</MenuItem>
        <MenuItem onClick={() => {setSelectedIndex(1); setAnchorEl(null)}} component={Link} to={`/account`}>My account</MenuItem>
        <MenuItem onClick={logOut}>Sign-out&nbsp;&nbsp;<FaSignOutAlt /></MenuItem>
      </Menu>
    </div>
  );
}
