import React from "react";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import { useShares } from '../../context/shares';
import { Grid, Card } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import RequestShareModal from './RequestShare';

const shareImgSrc = (share) => {
  if (share.photos === "default.jpg") {
    return `https://growve-images.s3.amazonaws.com/shares/default-share.jpg`;
  } else {
    return `https://growve-images.s3.amazonaws.com/shares/${share.id}/${share.photos}`;
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
  margin: 0 auto;
`;

const useStyles = makeStyles({
  root: {
    height: 100
  },
  media: {
    height: 60,
  },
  content: {
    height: 40,
  }
});

export const CommunityShares = () => {

  const { communityShares } = useShares();

  const classes = useStyles();

  const [shareModalShow, setShareModalShow] = React.useState({});

  const toggleShareModal = (shareId) => () => setShareModalShow({
    ...shareModalShow,
    [shareId]: !shareModalShow[shareId]
  })

  return(
    <Container>
        <Grid container spacing={1}>
          {communityShares.map((share) => (
              <React.Fragment key={share.id}>
                <Grid item xs={6} md={6}>
                  <Card className={classes.root}>
                    <CardActionArea
                      onClick={toggleShareModal(share.id)}
                    >
                      <CardMedia
                        className={classes.media}
                        image={shareImgSrc(share)}
                        title={share.name}
                      />
                      <CardContent className={classes.content}>
                        <Typography gutterBottom component="p">
                          {share.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <RequestShareModal 
                    show={shareModalShow[share.id]}
                    share={share}
                    onHide={toggleShareModal(share.id)}
                  />
                </Grid>
              </React.Fragment>
            )
          )}
        </Grid>
    </Container>
  )
}