import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Divider, Tooltip, Typography } from '@material-ui/core/';
// import ChatIcon from '@material-ui/icons/Chat';
import { ImBubbles } from 'react-icons/im';
import Zoom from '@material-ui/core/Zoom';
import { Badge, IconButton } from '@material-ui/core';
import { useUser } from '../../context/user';
import Friends from './FriendsSidebar';
import { FaArrowRight } from 'react-icons/fa';
import { InviteModal } from './InviteModal';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  fullList: {
    width: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
}));

export default function SwipeableTemporaryDrawer() {
  const { user } = useUser();

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{marginLeft: '1rem', textAlign: 'left'}}>
        <h5 style={{cursor: 'pointer', padding: '0.8rem'}} onClick={toggleDrawer('right', false)}>
          <FaArrowRight />&nbsp;Messaging
        </h5>
        <InviteModal style={{margin: '0 auto', marginBottom: '0.5rem'}}/>
      </div>
      <Divider />
      <Friends />
    </div>
  );

  return (
    <div>
      {/* <Button >{'right'}</Button> */}
      <IconButton 
        label="Messaging"
        onClick={toggleDrawer('right', true)}>
        <Tooltip title={<Typography color="inherit">Messaging</Typography>} arrow TransitionComponent={Zoom} aria-label="Messaging">
          <Badge badgeContent={user.messagesWaiting} color="secondary">
              <ImBubbles style={{color: "white"}} />
          </Badge>
        </Tooltip>
      </IconButton>
      <SwipeableDrawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        variant={"persistent"}
      >
        {list('right')}
      </SwipeableDrawer>
    </div>
  );
}
