import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useUser} from './user';
import { useConfig } from './config';

export const SeedsContext = createContext();

export function useSeeds() {
  return useContext(SeedsContext);
}

export const SeedsProvider = ({children}) => {
    const {api} = useConfig();

    const [seeds, setSeeds] = useState([]);
    const [tradeableSeeds, setTradeableSeeds] = useState([]);
    const {user} = useUser();
    const userId = user.id;

    const [isSeedsError, setIsSeedsError] = useState(false);
    const [addSeedSuccess, setAddSeedSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/seeds`, {userId}).then(result =>
            result.json().then(data => {
                setSeeds(data.seeds);
            })
        );
    }, [userId, api]);

    useEffect(() => {

        fetch(`${api}/seeds/tradeable`).then(result =>
        result.json().then(data => {
            setTradeableSeeds(data);
        })
        );
    }, [api]);

    function addSeed(newSeed) {
        return axios.post(`${api}/add_seed`, {
            ...newSeed,
            userId
        }).then(result => {
            if (result.status === 200) {
                setSeeds([...seeds, result.data]);
                return(result.data.id);
            } else {
                setIsSeedsError(true);
            }
        }).catch(e => {
            window.alert(e);
            setIsSeedsError(true);
        });
    }

    function editSeed(cb = false, data) {
        axios.post(`${api}/seed/${data.newSeedId}/edit/`, {...data}).then((result) => {
            let newSeeds = [...seeds];
            if (seeds.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newSeeds = [result.data, ...seeds];
            } else {
                newSeeds[seeds.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setSeeds(newSeeds);
            cb(false);
        })
    }

    function removeSeed(cb, seedId) {
        fetch(`${api}/user/${userId}/remove_seed/${seedId}`, {
            seedId,
            userId
        }).then(result => {
            if (result.status === 200) {
                var newSeeds = seeds.filter(function(oldSeeds) { return oldSeeds.id !== seedId });
                setSeeds(newSeeds);
                cb(false);
            } else {
                setIsSeedsError(true);
            }
        }).catch(e => {
            setIsSeedsError(true);
        });
    }

    return (
    <SeedsContext.Provider value={{seeds, tradeableSeeds, setSeeds, addSeed, editSeed, removeSeed, isSeedsError, addSeedSuccess, setIsSeedsError, setAddSeedSuccess}}>
        {children}
    </SeedsContext.Provider>
    )
}