import React, {useState} from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { ProfilePhoto } from "../Profile/ProfileImage";
import { useShares } from "../../context/shares";
import DateTimePicker from "react-datetime-picker";
import { timeConverter } from "../../core/utils";
import { FaArrowRight } from "react-icons/fa";


export const RequestShareModal = (props) => {

    const { addShareRequest } = useShares();
    const pickupDate = new Date();
    pickupDate.setDate(pickupDate.getDate());

    const [pickupTime, setPickupTime] = useState(pickupDate);
    const [message, setMessage] = useState("");
    const [quantity, setQuantity] = useState(1);

    let request = {shareId: props.share.id, pickupTime: pickupTime.getTime(), message, quantity};

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <ProfilePhoto person={props.share.owner} />
                &nbsp;You want some {props.share.name} from {props.share.owner.name}?
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} md={6}>
                        Pickup Availability:<br/>
                        <strong><u>From:</u> {timeConverter(props.share.pickupFrom)} <FaArrowRight/> <u>To:</u> {timeConverter(props.share.pickupTo)}</strong>
                        <br/><br/><Form.Label>Pickup Time:</Form.Label><br/>
                        <DateTimePicker
                            onChange={e => {
                                setPickupTime(new Date(e));
                            }}
                            value={pickupTime}
                            calendarIcon={null}
                            clearIcon={null}
                        /><br/><br/>
                        {/* <TextField
                            id="datetime-expiry"
                            label="Pickup Time"
                            type="datetime-local"
                            defaultValue={pickupTime}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={e => {
                                setPickupTime(new Date(e));
                            }}
                        /><br/><br/> */}

                        <p>
                        <Form.Label>How many do you want? (max {props.share.perPersonLimit} per person)</Form.Label>
                        <Form.Control
                            value={quantity}
                            type="number"
                            onChange={e => setQuantity(e.target.value)}
                            max={props.share.perPersonLimit}
                            min="1"
                        /></p>

                        <Form.Control
                            value={message}
                            as="textarea"
                            placeholder={"Message to " + props.share.owner.name + " (Optional)"}
                            rows="3"
                            onChange={e => setMessage(e.target.value)}
                            />
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={props.share.photos !== 'default.jpg' ? `https://growve-images.s3.amazonaws.com/shares/${props.share.id}/${props.share.photos}` : `https://growve-images.s3.amazonaws.com/shares/default-share.jpg`} alt={props.share.name} style={{width: "100%"}}/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={() => addShareRequest(request, props.onHide)}>I'm coming!</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RequestShareModal;