import { Typography } from '@material-ui/core';
import React, {useState} from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useUser } from '../../context/user';

export default function VerifyModal(props) {

  const { verifyEmail, verifyHelp, resendVerificationCode, resendComplete } = useUser();
  const [code, setCode] = useState('');

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Verify Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Typography variant="body2">
        Enter the 4 digit code from your welcome email to verify your email address
      </Typography><br/>
        <Form.Control
          type="number"
          placeholder="4 digit code"
          onChange={(e) => setCode(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer style={{margin: "0 auto"}}>
        <div>
          {resendComplete ?
            <Button disabled variant="success">
              New Code Sent!
            </Button>
          :
            <Button variant="warning" onClick={() => resendVerificationCode()}>
              Resend Code
            </Button>
          }
          &nbsp;&nbsp;&nbsp;<Button disabled={code.length !== 4} variant="success" type="submit"
            onClick={() => verifyEmail(code, props.onHide, props.errorSnack, props.successSnack)}
          >
            Submit
          </Button>
        </div><br/>
        { resendComplete && <Button
          onClick={() => verifyHelp(props.onHide, props.errorSnack, props.feedbackSnack)}
        >Didn't get it? Let us know</Button> }
      </Modal.Footer>
    </Modal>
  );
}