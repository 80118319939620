import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useUser} from './user';
import { useConfig } from './config';

export const RequestContext = createContext();

export function useRequest() {
  return useContext(RequestContext);
}

export const RequestProvider = ({children}) => {
    const {api} = useConfig();

    const [requests, setRequests] = useState([]);
    const {user, isLoggedIn, refreshUser } = useUser();
    const userId = user.id;

    const [isRequestError, setIsRequestError] = useState(false);
    const [addRequestSuccess, setAddRequestSuccess] = useState("");

    useEffect(() => {
        if (isLoggedIn) {
            fetch(`${api}/requests/${userId}/`, {userId}).then(response =>
                response.json().then(data => {
                    setRequests(data);
                })
            );
        }
    // eslint-disable-next-line
    }, [userId, api]);

    function addRequest(onHide, newRequest, senderMessage, fromDate = null, toDate = null) {
        axios.post(`${api}/user/${userId}/request/`, {
            ...newRequest,
            senderMessage,
            fromDate: newRequest.type === 'tools' ? fromDate.getTime() : null,
            toDate: newRequest.type === 'tools' ? toDate.getTime() : null
        }).then(result => {
            if (result.status === 200) {
                setIsRequestError(false);
                setAddRequestSuccess(true);
                // setRequests([result.data, ...requests]);
                onHide();
                setTimeout(() => {
                    setAddRequestSuccess(false);
                }, 5000);
            } else {
                setIsRequestError(true);
            }
        }).catch(e => {
            setIsRequestError(true);
        });
    }

    function cancelRequest(onHide, requestId) {
        fetch(`${api}/user/${userId}/cancel_request/${requestId}`, {
            requestId,
            userId
        }).then(result => {
            if (result.status === 200) {
                var newRequests = requests.filter(function(oldRequests) { return oldRequests.id !== requestId });
                setRequests(newRequests);
                onHide();
            } else {
                setIsRequestError(true);
            }
        }).catch(e => {
            setIsRequestError(true);
        });
    }

    function confirmRequest(requestId, message, useAddress, location) {
        axios.post(`${api}/requests/${requestId}/confirm`, {
            requestId,
            message,
            useAddress,
            location
        }).then(result => {
            if (result.status === 200) {
                var newRequests = requests.filter(function(oldRequests) { return oldRequests.id !== requestId });
                setRequests(newRequests);
                refreshUser(userId);
            } else {
                setIsRequestError(true);
            }
        }).catch(e => {
            setIsRequestError(true);
        });
    }

    function denyRequest(requestId, message) {
        axios.post(`${api}/requests/${requestId}/deny`, {
            requestId,
            message
        }).then(result => {
            if (result.status === 200) {
                var newRequests = requests.filter(function(oldRequests) { return oldRequests.id !== requestId });
                setRequests(newRequests);
                refreshUser(userId);
            } else {
                setIsRequestError(true);
            }
        }).catch(e => {
            setIsRequestError(true);
        });
    }

    return (
    <RequestContext.Provider value={{requests, confirmRequest, denyRequest, setRequests, addRequest, cancelRequest, isRequestError, setIsRequestError, addRequestSuccess, setAddRequestSuccess}}>
        {children}
    </RequestContext.Provider>
    )
}