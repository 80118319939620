import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useConfig } from '../../context/config';
import { useUser } from '../../context/user';
import { RiQuestionnaireFill } from 'react-icons/ri';
import DialogContentText from '@material-ui/core/DialogContentText';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { api } = useConfig();
  const { user } = useUser();
  const [feedback, setFeedback] = React.useState("");
 
  const [successSnack, setSuccessSnack] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnack(false);
  };

  const sendFeedback = () => {
    if (feedback !== "") {
      axios.post(`${api}/user/${user.id}/feedback`, {feedback}).then((result) => {
        if (result.status === 200) {
          setSuccessSnack(true);
          handleClose();
          setFeedback("");
        }
      })
    } else {
      setError(true);
    }
  }

  return (
    <div>
      <Tooltip title={<Typography>Ask A Question</Typography>}>
        <IconButton style={{color: 'white'}} onClick={handleClickOpen}>
          <RiQuestionnaireFill size={24} />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Contact the Growve team
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Send feedback or ask a question to the Growve team
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Feedback"
            type="text"
            multiline
            rows={10}
            fullWidth
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
        { error ? <Typography variant="h6">Please type something to send it.</Typography> : null }
        <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={() => sendFeedback()} variant="contained" color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={successSnack} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          Feedback sent successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}
