import React, { createContext, useContext, useState, useEffect } from 'react';

export const ConfigContext = createContext();

export function useConfig() {
  return useContext(ConfigContext);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const ConfigProvider = ({children}) => {
  const [api] = useState(process.env.NODE_ENV === "production" ? "https://api.growve.co" : "http://localhost:5000");

  const [images] = useState("https://growve-images.s3.amazonaws.com");

  return <ConfigContext.Provider value={{ api, images, useWindowDimensions }}>
    {children}  
  </ConfigContext.Provider>
}