import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useAuth } from './auth';
import { useConfig } from './config';
// import { usePeople } from './people';
import axios from 'axios';

export const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

const storedUser = JSON.parse(localStorage.getItem('loggedInUser'));

export const UserProvider = ({children}) => {

    const {api} = useConfig();

    const [user, setUser] = useState(storedUser);

    // const {refreshPeople} = usePeople();

    const [loginError, setLoginError] = useState(false);
    const [verifyError, setVerifyError] = useState(false);
    const [resendComplete, setResendComplete] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [mobileIndex, setMobileIndex] = useState("dashboard");

    const {setTokens} = useAuth();

    const isLoggedIn = !!user

    useEffect(() => {
        localStorage.setItem("loggedInUser", JSON.stringify(user || "") || null)
    }, [user]);


    function deleteUser(userId, feedback) {
        axios.post(`${api}/user/${userId}/delete`, {userId, feedback}).then(response =>
            {
                setTokens(null);
                setUser(null);
            });
    }


    function refreshUser(userId) {
        if (isLoggedIn) {
            fetch(`${api}/user/${userId}/get/${userId}`, {userId}).then(response =>
                response.json().then(data => {
                    setUser(data);
                    localStorage.setItem("loggedInUser", JSON.stringify(user));
                })
            );
        }
    }

    function login(userName, password) {
        setIsWorking(true);
        return axios.post(`${api}/user_login`, {
            userName,
            password
        }).then(result => {
            if (result.status === 200) {
                setTokens(result.data);
                setUser(result.data);
                setLoginError(false);
                setIsWorking(false);
            } else {
                setLoginError(true);
                setIsWorking(false);
            }
        }).catch(e => {
            console.error(e)
            setLoginError(true);
            setIsWorking(false);
        });
    }

    function logOut() {
        // window.location.replace("/");
        setTokens(null);
        setUser(null);
    }

    function resetPassword(email) {
        axios.post(`${api}/reset_password`, {
            email
        }).then(result => {
            if (result.status === 200) {
                window.alert("check your email");
            } else {
                window.alert("error");
            }
        });
    }

    function resendVerificationCode() {
        fetch(`${api}/user/${user.id}/resend-verification-code`).then((result) => {
            if (result.status === 200) {
                setResendComplete(true);
            } else {
                setResendComplete(false);
            }
        })
    }

    function changePassword(email, newPassword) {
        axios.post(`${api}/change_password`, {
            email,
            newPassword
        }).then(result => {
            if (result.status === 200) {
                window.alert("Password Changed");
            } else {
                // eslint-disable-next-line
                window.alert("Something went wrong ¯\_(ツ)_/¯");
            }
        });
    }

    const verifyHelp = (onHide, errorSnack, feedbackSnack) => {
        axios.post(`${api}/user/${user.id}/verify-help`).then(result => {
            if (result.status === 200) {
                onHide();
                feedbackSnack();
                return true;
            } else {
                errorSnack();
                return false;
            }
        });
    }

    const verifyEmail = (code, onHide, errorSnack, successSnack) => {
        axios.post(`${api}/user/${user.id}/verify`, {
            code
        }).then(result => {
            if (result.data === "verified") {
                onHide();
                successSnack();
                setUser({ ...user, 'verified': true});
                window.location.reload();
                return true;
            } else {
                errorSnack();
                setVerifyError(true);
                return false;
            }
        });
    }

    const updateUser = useCallback(
        (updatedUser) => axios.post(`${api}/user/${user.id}/update/`, {
            ...user,
            ...updatedUser
        }).then(result => {
            if (result.status === 200) {
                setUser(result.data);
                // refreshPeople();
                return true;
            } else {
                // eslint-disable-next-line
                return false;
            }
        }), [user, api]);


    const inviteFriend = useCallback(
        (newFriend, handleClose) => axios.post(`${api}/user/${user.id}/invite`, {
            ...newFriend
        }).then(result => {
            if (result.status === 200) {
                handleClose();
            } else {
                // eslint-disable-next-line
                window.alert("Something went wrong ¯\_(ツ)_/¯");
            }
        }), [user, api]);

    return (
    <UserContext.Provider value={{user, setUser, deleteUser, verifyEmail, verifyHelp, verifyError, resendComplete, resendVerificationCode, refreshUser, login, loginError, isWorking, isLoggedIn, logOut, changePassword, resetPassword, updateUser, inviteFriend, selectedIndex, setSelectedIndex, mobileIndex, setMobileIndex}}>
        {children}
    </UserContext.Provider>
    )
}