import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Body from "./pages/Body";
import { Container } from "semantic-ui-react";
import { useUser } from "./context/user";
import { FriendsProvider } from "./context/friends";
import { PeopleProvider } from "./context/people";
import { GardensProvider } from "./context/gardens";
import { MessageProvider } from "./context/messages";
import { RequestProvider } from "./context/request";
import { SharesProvider } from "./context/shares";
import { PostProvider } from "./context/posts";
import { PlantsProvider } from './context/plants';
import { ToolsProvider } from './context/tools';
import { CuttingsProvider } from './context/cuttings';
import { SeedsProvider } from './context/seeds';
import { HardscapingProvider } from './context/hardscaping';

function App() {

  const { isLoggedIn } = useUser();

  if (isLoggedIn) {

    return (
      <Container>
        <Router>
          <FriendsProvider>
            <PeopleProvider>
              <GardensProvider>
                <MessageProvider>
                  <RequestProvider>
                    <SharesProvider>
                      <PostProvider>
                        <PlantsProvider>
                          <ToolsProvider>
                            <CuttingsProvider>
                              <SeedsProvider>
                                <HardscapingProvider>
                                  <Body />
                                </HardscapingProvider>
                              </SeedsProvider>
                            </CuttingsProvider>
                          </ToolsProvider>
                        </PlantsProvider>
                      </PostProvider>
                    </SharesProvider>
                  </RequestProvider>
                </MessageProvider>
              </GardensProvider>
            </PeopleProvider>
          </FriendsProvider>
        </Router>
      </Container>
    );
  }
  else {
    return (
      <Container>
        <Router>
          <Body />
        </Router>
      </Container>
    );
  }
}

export default App;
