import React from "react";
import { Image } from "react-bootstrap";
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const styles = {
  PersonImage: {
    width: "50px",
    height: "50px",
    border: "1px solid white"
  },
  NavPersonImage: {
    width: "50px",
    height: "50px",
    border: "1px solid white",
    zIndex: "999"
},
}

const NavProfile = styled(Image)`
    width: 35px;
    height: 35px;
    border: 2px solid white;
    z-index: 999;
    @media (max-width: 600) {
      max-width: 25px !important;
      max-height: 25px !important;
    }
`;

export const ProfilePhoto = (props) => {
  if (!props.resource) {
    if (props.person.profilePhoto === "default.jpg" || props.person.profilePhoto === null) {
      return(
        <>
        <Image style={styles.PersonImage} roundedCircle data-tip={props.person.name} src={("https://growve-images.s3.amazonaws.com/profiles/default-profile.jpg")} />
        <ReactTooltip />
        </>
        )
    } else {
      return(
        <>
        <Image style={styles.PersonImage} roundedCircle data-tip={props.person.name} src={("https://growve-images.s3.amazonaws.com/profiles/") + props.person.id + "/" + props.person.profilePhoto} />
        <ReactTooltip />
        </>
        )
    }
  } else {
    // resource search results
    if (props.resource.profilePhoto === "default.jpg" || props.resource.profilePhoto === null) {
      return(
        <>
        <Image style={styles.PersonImage} data-tip={props.resource.username} rounded src={("https://growve-images.s3.amazonaws.com/profiles/default-profile.jpg")} />
        <ReactTooltip />
        </>
        )
    } else {
      return(
        <>
        <Image style={styles.PersonImage} data-tip={props.resource.username} rounded src={("https://growve-images.s3.amazonaws.com/profiles/") + props.resource.userId + "/" + props.resource.profilePhoto} />
          <ReactTooltip />
          </>
        )
    }
  }
}

export const NavProfilePhoto = (props) => {
  if (props.person.profilePhoto === "default.jpg" || props.person.profilePhoto === null) {
    return(
      <>
      <NavProfile roundedCircle src={("https://growve-images.s3.amazonaws.com/profiles/default-profile.jpg")} />
      <ReactTooltip />
      </>
      )
  } else {
    return(
      <>
      <NavProfile roundedCircle src={("https://growve-images.s3.amazonaws.com/profiles/") + props.person.id + "/" + props.person.profilePhoto} />
      <ReactTooltip />
      </>
      )
  }
}