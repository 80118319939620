import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { useShares } from "../../context/shares";
import updateImage from '../../core/updateImage';
import Resizer from 'react-image-file-resizer';
import { Button, TextField, FormGroup, Typography, OutlinedInput } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
import DateTimePicker from "react-datetime-picker";
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  Accordion: {textAlign: "left", padding:"0", margin:"0", color:"black"},
  List: {
    fontSize:".8em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    minWidth: "100%",
  },
  Modal: {
    textAlign: "center",
  },
  Button: {
    margin: "2px",
    fontSize: "0.9em",
  },
  InputField: {
    marginBottom: "1rem",
    marginTop: "1rem",
    width: "100%",
  },
  DateInput: {
    width: "100% !important",
  },
  Description: {
    width: "100% !important",
  }
};

const ShareImage = styled.img`
    width: 100%;
    border-radius: '5px !important';
`;

// const AntSwitch = withStyles((theme) => ({
//   root: {
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: 'flex',
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     '&$checked': {
//       transform: 'translateX(12px)',
//       color: theme.palette.common.white,
//       '& + $track': {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     boxShadow: 'none',
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);

export const AddShareModal = (props) => {

  const {addShare, editShare} = useShares();
  const [nullError, setNullError] = useState(false);

  const fromDate = new Date();
  const toDate = new Date();
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + 7);
  fromDate.setDate(fromDate.getDate() + 1);
  toDate.setDate(toDate.getDate() + 1);
  
  const [expiry, setExpiry] = useState(expirationDate);
  const [shareName, setShareName] = useState("");
  const [shareQuantity, setShareQuantity] = useState(1);
  const [quantityType] = useState(false);
  const [shareDescription, setShareDescription] = useState("");
  const [sharePhotos, setSharePhotos] = useState("");
  const [moreDetail, setMoreDetail] = useState(false);
  // const [pickupFrom, setPickupFrom] = useState(fromDate);
  // const [pickupTo, setPickupTo] = useState(toDate);
  const [pickupInstructions, setPickupInstructions] = useState("");
  const [perPersonLimit, setPerPersonLimit] = useState(1);

  // const newShare = {shareName, shareDescription, shareQuantity, quantityType, sharePhotos, expiry: expiry.getTime(), pickupFrom: pickupFrom.getTime(), pickupTo: pickupTo.getTime(), pickupInstructions, perPersonLimit};

  const newShare = {shareName, shareDescription, shareQuantity, quantityType, sharePhotos, expiry: expiry.getTime(), pickupInstructions, perPersonLimit};

  const filePreview = "https://growve-images.s3.amazonaws.com/shares/default-share.jpg";
  
  const resizeFile = (newfile) => new Promise(() => {
    Resizer.imageFileResizer(newfile, 1200, 900, 'JPEG', 100, 0,
    uri => {
      setImage(uri);
    },
    'blob'
    );
  });

  const [file, setFile] = useState();
  const [image, setImage] = useState();

  const fileRef = useRef();

  const openFileDialog = () => fileRef.current.click()

  const fileChosen = useCallback((file) => {
    setFile(file);
    resizeFile(file);
  }, []);

  const handleFileChange = useCallback(
    ({ target: { files: [file] = [] } }) => fileChosen(file),
    [fileChosen],
  );

  const upload = useCallback((newId) => {
    return updateImage('shares', newId, image, file.name).then((sharePhoto) => {
      setSharePhotos(sharePhoto);
      return sharePhoto;
    });
  }, [image, file, setSharePhotos]);

  function addNewShare() {
    if (shareName !== "") {
    addShare(newShare).then((id) => {
      if (file !== undefined) {
        upload(id).then((gPhoto) => {
            editShare(props.onHide, {
              sharePhotos: gPhoto,
              shareId: id
            });
            clear();
        });
      } else {
        props.onHide();
        clear();
      }
    });
    } else {
      setNullError(true);
    }
  }

  function clear() {
    setShareName("");
    setShareDescription("");
    setSharePhotos("");
    setShareQuantity(1);
    setFile();
    setImage();
  }

  return (
    <Modal
      {...props}
      size={moreDetail ? 'lg' : 'md'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={styles.Modal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share produce with your community
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col sm={12} md={moreDetail ? 6 : 12}>
              {/* <InputLabel htmlFor="sharename">What are you sharing?</InputLabel> */}
              <OutlinedInput
                id="sharename"
                required
                style={styles.InputField}
                type="text"
                placeholder="What are you sharing?"
                value={shareName}
                // helperText={`${shareName.length}/20`}
                inputProps={{ maxLength: 20 }}
                onChange={e => {
                    setShareName(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {`${shareName.length}/20`}
                  </InputAdornment>
                }
              />

              <div style={{position: 'relative', textAlign: 'center', color: 'whitesmoke'}}>
                <ShareImage src={file ? URL.createObjectURL(file) : filePreview} onClick={openFileDialog} style={file ? {opacity: '1', borderRadius: '5px'} : {borderRadius: '5px', opacity: '0.5', zIndex: '2'}}/>
                {/* <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '3rem', fontWeight: 'bold', zIndex: '1'}}>Choose Photo</div> */}
                {file ? 
                  <Button
                    style={{color: 'whitesmoke', position: 'absolute', bottom: '5%', left: '50%', transform: 'translate(-50%, 0%)', fontSize: '1.2rem', fontWeight: 'bold', zIndex: '1'}}
                    onClick={openFileDialog}
                  >
                  Change Photo
                  </Button>
                :
                  <Button
                    style={{color: 'whitesmoke', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '2.5rem', fontWeight: 'bold', zIndex: '1'}}
                    onClick={openFileDialog}
                  >
                  Choose Photo
                  </Button>
                }
              </div>
              {/* <Button
                css="border: 0; padding: 0;"
                onClick={openFileDialog}
              >
              Choose a photo
              </Button> */}
              <Form.Control
                style={{display: "none"}}
                multiple={false}
                onChange={handleFileChange}
                ref={fileRef}
                type="file"
              />
              <Button style={{margin: '1rem'}} variant="outlined" onClick={() => setMoreDetail(!moreDetail)}>
                {moreDetail ?
                  <small>Less Details</small>
                :
                  <small>More Details</small>
                }
              </Button>
            </Col>
            { moreDetail &&
              <Col sm={12} md={6}>
              <FormGroup row style={styles.InputField}>
              <TextField
                  variant="outlined"
                  style={{minWidth: "100%", marginBottom: "1.5rem"}}
                  multiline={true}
                  rows="2"
                  value={shareDescription}
                  label="Describe what you are sharing"
                  onChange={e => {
                      setShareDescription(e.target.value);
                  }}
                  placeholder="Write a description for your share"
                />

                  <TextField
                    type="number"
                    label="How much are you giving?"
                    variant="outlined"
                    value={shareQuantity}
                    style={{width: "40%"}}
                    onChange={e => {
                        setShareQuantity(e.target.value);
                    }}
                  />
                  {/* <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Quantity</Grid>
                      <Grid item>
                        <AntSwitch checked={quantityType} onChange={e => setQuantityType(e.target.checked)} name="checkedC" />
                      </Grid>
                      <Grid item>KG</Grid>
                    </Grid>
                  </Typography> */}
                  <TextField
                    type="number"
                    label="Per person limit?"
                    variant="outlined"
                    value={perPersonLimit}
                    style={{width: "40%", marginLeft: "2rem"}}
                    onChange={e => {
                        setPerPersonLimit(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>


                <label>How long will it last?</label>&nbsp;
                {/* <TextField
                  id="datetime-local"
                  label="Expiration"
                  type="datetime-local"
                  onChange={e => {
                      setExpiry(new Date(e));
                  }}
                  value={expiry}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <DateTimePicker
                  onChange={e => {
                      setExpiry(new Date(e));
                  }}
                  value={expiry}
                  clearIcon={null}
                  calendarIcon={null}
                />



                {/* <label>Pickup Time</label><br/> */}

                {/* From:&nbsp;
                <DateTimePicker
                  onChange={e => {
                      setPickupFrom(new Date(e));
                  }}
                  value={pickupFrom}
                  clearIcon={null}
                  calendarIcon={null}
                /><br/><br/>

                To:&nbsp;
                <DateTimePicker
                  onChange={e => {
                      setPickupTo(new Date(e));
                  }}
                  value={pickupTo}
                  clearIcon={null}
                  calendarIcon={null}
                /> */}

                <br/><br/>  

                <TextField
                  variant="outlined"
                  style={{minWidth: "100%"}}
                  multiline={true}
                  rows="2"
                  value={pickupInstructions}
                  label="Pickup instructions"
                  onChange={e => {
                      setPickupInstructions(e.target.value);
                  }}
                  placeholder="Write any details of the pickup requirements.."
                />
              </Col>
            }
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        { nullError ?
          <Typography style={{color: 'red'}}>
            You must enter a name
          </Typography>
        :
          null
        }
        <Button
          style={styles.Button}
          variant="contained"
          color="primary"
          onClick={() => addNewShare()}>Share</Button>
        {/* <Button style={styles.Button} onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default AddShareModal;