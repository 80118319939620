import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FaHandHoldingHeart, FaInfoCircle, FaPeopleCarry, FaRegQuestionCircle, FaSearch, FaToolbox, FaUserFriends } from 'react-icons/fa';
import { useUser } from '../../context/user';

const styles = {
  icons: {
    color: 'grey',
    border: '#007bff',
    '&:hover': {
      color: 'blue'
    }
  }
}

export default function MoreMenu() {

  const {user} = useUser();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton style={{border: '0 !important'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to={`/friends`}><FaUserFriends style={styles.icons} size={20}/>&nbsp;Friends</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={`/community/info`}><FaInfoCircle style={styles.icons} size={20}/>&nbsp;Community Info</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={`/community/members`}><FaPeopleCarry style={styles.icons} size={20}/>&nbsp;Members</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={`/searchshares`}><FaSearch style={styles.icons} size={20}/>&nbsp;Produce</MenuItem>
        {/* <MenuItem onClick={handleClose} component={Link} to={`/wishes`}><FaHatWizard style={styles.icons} size={20}/>&nbsp;Wishes</MenuItem> */}
        <MenuItem onClick={handleClose} component={Link} to={`/requests`}><Badge badgeContent={user.requestsWaiting} color="secondary"><FaRegQuestionCircle style={styles.icons} size={20} />&nbsp;Requests</Badge></MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={`/shares`}><FaHandHoldingHeart style={styles.icons} size={20}/>&nbsp;Shares</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={`/resources`}><FaToolbox style={styles.icons} size={20}/>&nbsp;My Resources</MenuItem>
      </Menu>
    </div>
  );
}
