import React, {Suspense} from 'react'
import styled from "styled-components";

import { Link, Redirect } from "react-router-dom";
import { useUser } from "../context/user";
import "./Home.css";

import headerImage from "../img/homepage_hero.webp";
import becomeImage from "../img/become.png";
import step1Image from "../img/community-garden.webp";
import step2Image from "../img/garden_tools.webp";
import step3Image from "../img/create-your-dream-garden.webp";
import slider1 from "../img/hipster-gardening.webp";
import slider2 from "../img/couple-gardening-together.webp";
import slider3 from "../img/harvesting-fresh-produce.webp";
import slider4 from "../img/ornamental-gardens.webp";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useSpring, animated } from 'react-spring';
import { useWindowDimensions } from '../context/config';

const Footer = React.lazy(() => import("./Footer"));
const RandomGardens = React.lazy(() => import("../components/Common/RandomGardens"));

const AutoAwesomeSlider = withAutoplay(AwesomeSlider);

const useStyles = makeStyles({
  grid: {
    overflowX: 'hidden',
    maxWidth: '100vw',
  },
  card: {
    maxWidth: 345,
    borderRadius: '10px',
    margin: '0 auto !important',
  },
  media: {
    height: 280,
  },
  CTAButton: {
    padding: "1.2rem", 
    borderRadius: "3rem",
    margin: "10px",
    fontWeight: "500",
    fontSize: "1.1rem",
    // border: '2px solid whitesmoke',
    // backgroundColor: 'rgb(239, 255, 232)', 
    color: 'white !important',
    // '&:hover': {
    //   backgroundColor: 'rgb(40,100,69)',
    // }
  },
});

const GardeningCommunityImage = styled.img`
  max-width: 25vw;
  border-radius: 8px;
  @media (max-width: 900px) {
    max-width: 80vw;
  }
`;

function Home(props) {

  const referer = props.location.state ? props.location.state.referer : '/dashboard';

  const { width } = useWindowDimensions();

  const { isLoggedIn } = useUser();
  const classes = useStyles();

  const animate = useSpring({ 
    to: { opacity: 1 }, 
    from: { opacity: 0 },
    delay: 200,
  })

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  } else {

    return (
      <animated.div style={animate}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} lg={12}>
            <AutoAwesomeSlider 
              className="aws-btn"
              style={{marginBottom: "1rem", height: "50vh"}}
              organicArrows={true}
              bullets={false}
              play={true}
              cancelOnInteraction={true}
              interval={6000}
              mobileTouch={true}
              >
              <div data-src={slider1}>
                <p>Grow your vegetable garden</p>
              </div>
              <div data-src={slider2}>
                <p>Get to know others in your community and learn to garden.</p>
              </div>
              <div data-src={slider3}>
                <p>Grow what you need and share your excess produce with the community</p>
              </div>
              <div data-src={slider4}>
                <p>Showcase your garden for your community and the world to see</p>
              </div>
            </AutoAwesomeSlider>
          </Grid>
          <Grid container alignContent="center" alignItems="center">
            <Grid item xs={12} lg={12} style={{textAlign: 'center'}}>
              <Typography variant="h1" style={{margin: '0 auto', fontWeight: '500', marginTop: '2rem', color: 'white', fontSize: '2rem', textAlign: 'center'}}>
                Connect, Share, & Create Gardens with Growve.
              </Typography><br/>
            </Grid>
            <Grid item xs={12} lg={12} style={{textAlign: 'center'}}>
              <Typography style={{color: "white", fontSize: '1.3rem', maxWidth: '800px', margin: '0 auto'}}>
                Connect with local gardeners, swap and share your gardening tools (and more!), and get advice from locals and experts to help you grow your dream garden.
              </Typography><br/>
            </Grid>
            <Grid item xs={12} lg={12} style={{textAlign: 'center'}}>
              <Button className={classes.CTAButton} component={Link} variant="contained" color="primary" size='small' to="/signup">
                Sign Up For Free
              </Button><br/>
            </Grid>
          </Grid>
          
          <Grid container className={classes.grid} style={{marginTop: '2rem', padding: '3rem 0 3rem 0', border: "0", backgroundImage: `url(${headerImage}`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>

            <Grid item xs={12} md={4}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={step1Image}
                    title="Creating a gardening community"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Gardening Community
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Meet, make friends and garden with other gardening enthusiasts from your community
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent: 'center'}}>
                  <Button component={Link} to="/signup/" size="large" color="primary" variant="outlined">
                    Connect
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} style={{textAlign: 'center', justifyContent: 'center', alignContent: 'middle'}}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={step2Image}
                    title="Playing our part in the sharing economy"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    Sharing Economy
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Use the local sharing economy to get access to tools, plants, seeds and more
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent: 'center'}}>
                  <Button component={Link} to="/signup/" size="large" color="primary" variant="outlined">
                    Share
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={step3Image}
                    title="Learning to garden can be fun and easy with the right support"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Learn To Garden
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Use the resource library of your local community to build and maintain your garden
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent: 'center'}}>
                  <Button component={Link} to="/signup/" size="large" color="primary" variant="outlined">
                    Learn
                  </Button>
                </CardActions>
              </Card>
              </Grid>
          </Grid>

          { width < 600 && 
            <Grid item xs={12} lg={12} style={{textAlign: 'center', padding: '2rem'}}>
              <Button className={classes.CTAButton} component={Link} variant="contained" color="primary" size='medium' to="/how-it-works">
                How It Works
              </Button><br/>
            </Grid>
          }

          <Grid container style={{backgroundColor: 'white', justifyContent: 'center', padding: '3rem'}}>
            <Grid item xs={12} md={6} style={{margin: '0 auto'}}>
              <h4>Growve is your Gardening Community</h4><br/>
                  <Typography style={{color: "black", fontSize: '1rem'}}>
                    Growve has been designed for gardeners by gardeners specifically to meet others in their community to connect, share resources and produce.
                    <br/><br/>At Growve we want to inspire you to create amazing gardens that bring you and others happiness, share your space with others to see and inspire.
                  </Typography><br/>
                  
                  <Button className={classes.CTAButton} component={Link} variant="contained" color="primary" to="/signup">
                    Sign Up For Free!
                  </Button><br/><br/>
            </Grid>
            <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              <GardeningCommunityImage src={becomeImage} alt="Gardening Community" />
            </Grid>
          </Grid>
        <Grid item xs={12} lg={12}>
          <Suspense fallback={<div>Loading...</div>}>
            <RandomGardens />
          </Suspense>
        </Grid>
        </Grid>
        <Footer />
      </animated.div>
    );
  }
}

export default Home;