import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const CuttingsContext = createContext();

export function useCuttings() {
  return useContext(CuttingsContext);
}

export const CuttingsProvider = ({children}) => {
    const {api} = useConfig();
    const {user} = useUser();
    const userId = user.id;

    const [cuttings, setCuttings] = useState([]);

    const [isCuttingsError, setIsCuttingsError] = useState(false);
    const [addCuttingSuccess, setAddCuttingSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/cuttings/${userId}`, {userId}).then(result =>
            result.json().then(data => {
                setCuttings(data);
            })
        );
    }, [userId, api]);


    function addCutting(newCutting) {
        return axios.post(`${api}/cuttings/${userId}/add`, {
            ...newCutting,
            userId
        }).then(result => {
            if (result.status === 200) {
                setCuttings([result.data, ...cuttings]);
                return(result.data.id);
            } else {
                setIsCuttingsError(true);
            }
        }).catch(e => {
            setIsCuttingsError(true);
        });
    }

    function removeCutting(onHide, cuttingId) {
        fetch(`${api}/cuttings/${cuttingId}/remove`).then(result => {
            if (result.status === 200) {
                let newCuttings = cuttings.filter(function(oldCuttings) {return oldCuttings.id !== cuttingId});
                setCuttings(newCuttings);
                onHide();
            } else {
                setIsCuttingsError(true);
            }
        });
    }

    function editCutting(cb, data) {
        axios.post(`${api}/cuttings/${userId}/edit`, {...data})
        .then((result) => {
            let newCuttings = [...cuttings];
            if (cuttings.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newCuttings = [result.data, ...cuttings];
            } else {
                newCuttings[cuttings.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setCuttings(newCuttings);
            cb(false);
        })
    }

    return (
    <CuttingsContext.Provider value={{cuttings, addCutting, editCutting, removeCutting, setCuttings, isCuttingsError, addCuttingSuccess, setIsCuttingsError, setAddCuttingSuccess}}>
        {children}
    </CuttingsContext.Provider>
    )
}