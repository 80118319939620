import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {EmailShareButton, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, PinterestShareButton, PinterestIcon, TwitterShareButton, TwitterIcon, EmailIcon} from 'react-share';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  shareButton: {
    margin: "1rem",
  },
}));

export const ShareModal = ({post, show, onHide}) => {

  const classes = useStyles();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Share your post
      </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
        <EmailShareButton
          subject={`From ${post.user.username} via Growve`}
          body={`${post.user.username} wanted to share their post from Growve with you: ${post.message}`}
          separator=" "
        >
          <EmailIcon size={32} /><br/>Email
        </EmailShareButton>
        <FacebookShareButton
          url={`https://www.growve.co/post/${post.id}`}
          quote={`${post.message}`}
          className={classes.shareButton}
        >
          <FacebookIcon size={32} round /><br/>Facebook
        </FacebookShareButton>
        <WhatsappShareButton
          url={`https://www.growve.co/post/${post.id}`}
          title={`${post.message}`}
          className={classes.shareButton}
        >
          <WhatsappIcon size={32} round /><br/>Whatsapp
        </WhatsappShareButton>
        <PinterestShareButton
            url={`https://www.growve.co/post/${post.id}`}
            media={`https://growve-images.s3.amazonaws.com/posts/${post.id}/${post.photo}`}
            className={classes.shareButton}
          >
            <PinterestIcon size={32} round /><br/>Pinterest
        </PinterestShareButton>
        <TwitterShareButton
          url={`https://www.growve.co/post/${post.id}`}
          title={`${post.message}`}
          className={classes.shareButton}
        >
          <TwitterIcon size={32} round /><br/>Twitter
        </TwitterShareButton>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareModal;