import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from "./context/user";

function PrivateRoute({ component: Component, ...rest }) {

    const {isLoggedIn} = useUser();

    return(
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/login", state: { referer: props.location } }}
                />
            )
            }
        />
    );
}

export default PrivateRoute;