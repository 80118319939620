import axios from 'axios';

function UpdateImage(resourceType, resourceId, file, filename) {
  const api = process.env.NODE_ENV === "production" ? "https://api.growve.co" : "http://localhost:5000";

  return axios.post(`${api}/aws/upload_image`, {
    name: `${resourceType}/${resourceId}`,
    file,
    filename
  }).then(({data}) => {
    const formData = new FormData();
    Object.entries(data.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);
    
    return axios.post(data.url, formData, { headers: {'Content-Type': undefined },})
      .then(() => {
        return filename; 
      });
  });
}

export default UpdateImage;