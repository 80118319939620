import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useUser} from './user';
import { useConfig } from './config';

export const HardscapingContext = createContext();

export function useHardscaping() {
  return useContext(HardscapingContext);
}

export const HardscapingProvider = ({children}) => {
    const {api} = useConfig();

    const [hardscapings, setHardscapings] = useState([]);
    const {user} = useUser();
    const userId = user.id;

    const [isHardscapingError, setIsHardscapingError] = useState(false);
    const [addHardscapingSuccess, setAddHardscapingSuccess] = useState("");

    useEffect(() => {

        fetch(`${api}/user/${userId}/hardscapings`, {userId}).then(response =>
        response.json().then(data => {
                setHardscapings(data);
            })
        );
    }, [userId, api]);

    function addHardscaping(newHardscaping) {
        return axios.post(`${api}/add_hardscaping`, {
            ...newHardscaping,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsHardscapingError(false);
                setAddHardscapingSuccess(true);
                setHardscapings([result.data, ...hardscapings]);
                setTimeout(() => {
                    setAddHardscapingSuccess(false);
                }, 5000);
                return(result.data.id);
            } else {
                setIsHardscapingError(true);
            }
        }).catch(e => {
            setIsHardscapingError(true);
        });
    }

    function editHardscaping(cb = false, data) {
        let url = `${api}/hardscaping/${data.newHardscapingId}/edit/`;
        axios.post(url, {...data}).then((result) => {
            let newHardscapings = [...hardscapings];
            if (hardscapings.map(function(e) { return e.id; }).indexOf(result.data.id) < 0) {
                newHardscapings = [result.data, ...hardscapings];
            } else {
                newHardscapings[hardscapings.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
            }
            setHardscapings(newHardscapings);
            cb(false);
        })
    }

    function removeHardscaping(hardscapingId) {
        fetch(`${api}/user/${userId}/remove_hardscaping/${hardscapingId}`, {
            hardscapingId,
            userId
        }).then(result => {
            if (result.status === 200) {
                var newHardscapings = hardscapings.filter(function(oldHardscapings) { return oldHardscapings.id !== hardscapingId });
                setHardscapings(newHardscapings);
            } else {
                setIsHardscapingError(true);
            }
        }).catch(e => {
            setIsHardscapingError(true);
        });
    }

    return (
    <HardscapingContext.Provider value={{hardscapings, addHardscaping, editHardscaping, removeHardscaping, isHardscapingError, addHardscapingSuccess}}>
        {children}
    </HardscapingContext.Provider>
    )
}