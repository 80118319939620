export const timeSince = (date) => {

  var seconds = Math.floor(((new Date().getTime()/1000) - date)),
  interval = Math.floor(seconds / 31536000);

  if (interval > 1) return interval + "y";

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return interval + "m ago";

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return interval + "d ago";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return interval + "h ago";

  interval = Math.floor(seconds / 60);
  if (interval > 1) return interval + "m ago";

  // return Math.floor(seconds) + "s";
  return 'Just now';
}

export const timeConverterFullDate = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  // var sec = a.getSeconds();
  // var time = date + ' ' + month + ' at ' + hour + ':' + min + ':' + sec ;
  var time = date + ' ' + month + ' ' + year;
  return time;
}

export const timeConverter = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  // var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var time = date + ' ' + month + ' at ' + hour + ':' + min + ':' + sec ;
  var time = date + ' ' + month + ' at ' + hour + ':' + min;
  return time;
}

export const timeConverterShort = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + ' ' + month;
  return time;
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatDateTime = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return ([year, month, day].join('-') + " " + [hour] + ":00");
}