import React, { useState, useCallback, useRef, memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaPaperPlane, FaUsers, FaTools, FaSeedling, FaCamera, FaSearch, FaInfoCircle } from "react-icons/fa";
import { GiPlantsAndAnimals } from "react-icons/gi";
import { Button, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import Resizer from 'react-image-file-resizer';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Posts from "../components/Community/Posts";
import updateImage from '../core/updateImage';
import { Helmet } from "react-helmet";

import { CommunityShares } from "../components/Share/Community";
// import CommunitySpeedDial from "../components/Community/SpeedDial";
import { CommunityMap } from "../core/maps";
import { useUser } from "../context/user";
import { useGardens } from "../context/gardens";
import { usePost } from "../context/posts";

import './Community.css';
import { useWindowDimensions } from "../context/config";
import { FaHandHoldingHeart } from "react-icons/fa";
import AddShareModal from "../components/Share/AddShare";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white !important', 
    color: 'black !important',
    borderRadius: '0.5rem',
    marginTop:"1rem", 
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    height: "100px",
    marginTop: "10px",
    width: "100%",
    boxShadow: "4px 4px 16px rgba(100,100,100,0.2)",
    padding: "15px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: "60px",
  },
  card: {
    flexGrow: 1,
    margin: 10
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  editroot: {
    '& > *': {
      margin: 5,
    },
  },
  media: {
    height: 140,
  },
  content: {
    minHeight: 50,
  },
  actionArea: {
    float: 'right',
  }
}));

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 0;
    border: 0;
  }
`;

const Header = styled.h4`
  text-align: center;
  color: white;
`;

const Card = styled.div`
    /* background-color: rgba(225,225,225,0.95); */
    width: 95%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    /* border: 1px solid rgba(220,220,220,0.3); */
    /* box-shadow: 4px 4px 10px rgba(50,50,50,0.5); */
    overflow-x: hidden;
    overflow-y: hidden;
    @media (max-width: 600px) {
      background-color: rgba(255,255,255,0.0);
      border: none;
      box-shadow: none;
    }
`;

const WelcomeHeader = styled.h4`
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;

  @media (min-width: 600px) {
    display: none;
  }
`;


const Community = memo(() => {

  const { user } = useUser();
  const { gardens } = useGardens();
  const { addPost, editPost } = usePost();
  const { width } = useWindowDimensions();
  
  const [ newPost, setNewPost ] = useState("");
  const [ newPostGardenId, setNewPostGardenId ] = useState("");
  const [ postPhoto, setPostPhoto ] = useState("");

  const [file, setFile] = useState();
  const [image, setImage] = useState();

  const classes = useStyles();

  const resizeFile = (newfile) => new Promise(() => {
    Resizer.imageFileResizer(newfile, 1200, 900, 'JPEG', 100, 0,
    uri => {
      setImage(uri);
    },
    'blob'
    );
  });

  const fileRef = useRef();

  const openFileDialog = () => fileRef.current.click()

  const fileChosen = useCallback((file) => {
    setFile(file);
    resizeFile(file);
  }, []);

  const handleFileChange = useCallback(
    ({ target: { files: [file] = [] } }) => fileChosen(file),
    [fileChosen],
  );

  const upload = useCallback((newId) => {
    return updateImage('posts', newId, image, file.name).then((postPhoto) => {
      setPostPhoto(postPhoto);
      return postPhoto;
    });
  }, [file, image, setPostPhoto]);

  function postToCommunity() {
    if(newPost !== "") {
      addPost(newPost, postPhoto, true, newPostGardenId).then((id) => {
        if (file !== undefined && file !== '') {
          upload(id).then((gPhoto) => {
              editPost({
                photo: gPhoto,
                postId: id
              });
          });
        }
      });
      setNewPost("");
      setFile("");
      setNewPostGardenId("");
    }
  }

  const [addShareModalShow, setAddShareModalShow] = React.useState({});
  const newShare = 9999999;
  const toggleAddShareModal = (newShare) => () => setAddShareModalShow({
    ...addShareModalShow,
    [newShare]: !addShareModalShow[newShare]
  });

  return (
    <Container>
    <Helmet>
      <title>Growve - Community News Feed</title>
      <meta name="description" content="Community Gardening News Feed" />
    </Helmet>
        <WelcomeHeader>Your community</WelcomeHeader>
          <Row>
            <Col sm={12} md={9}>
              { width > 600 ?
                <Row>
                  <Col>
                    <Header>Posts</Header>
                  </Col>
                </Row>
                :
                <div style={{position: "fixed", bottom: "70px", right: "20px", zIndex: "9999999"}}>
                  {/* <CommunitySpeedDial /> */}
                  <Button 
                    style={{zIndex: "999999", borderRadius: '25px', boxShadow: '1px 2px rgba(0,0,0,0.3)'}}
                    onClick={toggleAddShareModal(newShare)}
                  ><FaHandHoldingHeart size={24}/> <span style={{fontSize: "0.8rem"}}>Share</span></Button>
                  <AddShareModal 
                    show={addShareModalShow[newShare]}
                    onHide={toggleAddShareModal(newShare)}
                  />
                </div>
              }
                    <Card>
                      <Paper component="form" className={classes.root}>
                        <InputBase
                            value={newPost}
                            onChange={(e) => setNewPost(e.target.value)}
                            className={classes.input}
                            multiline={true}
                            rows="4"
                            placeholder={user.verified ? "Ask a question or something for your community and friends to read..." : "Verify your account to post to your community"}
                            inputProps={{ 'aria-label': 'community post' }}
                        />
                        {file ?
                          <>
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton color="primary" onClick={openFileDialog} className={classes.iconButton} aria-label="directions">
                              <img src={URL.createObjectURL(file)} style={{height: "80px", borderRadius: "5px"}} alt="post"/>
                            </IconButton>
                          </>
                        :
                          <>
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton onClick={openFileDialog} color="primary" className={classes.iconButton} aria-label="directions">
                              <FaCamera size={32}/>
                            </IconButton>
                          </>
                        }
                      </Paper>
                      {user.public && user.verified ?
                      <span>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="age-native-simple">Garden</InputLabel>
                          <Select
                            native
                            value={newPostGardenId}
                            onChange={(e) => {setNewPostGardenId(e.target.value)}}
                            inputProps={{
                              name: 'Garden',
                              id: 'age-native-simple',
                            }}
                          >
                            <option aria-label="None" value={""} />
                            {gardens.filter((g) => g.community).map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <Button style={{ marginTop: "1rem", padding: "1rem 1rem 1rem 1rem", borderRadius: '5px' }} onClick={() => postToCommunity()} >
                          Post <FaPaperPlane />
                        </Button>
                        <Form.Control
                          style={{display: "none"}}
                          multiple={false}
                          onChange={handleFileChange}
                          ref={fileRef}
                          type="file"
                        />
                      </span>
                       : 
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Your profile needs to be verified & public to post.</Tooltip>}>
                          <span className="d-inline-block">
                            <Button disabled style={{marginTop:"1rem", pointerEvents: 'none'}} >
                              Post To Community <FaPaperPlane />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      }
                      <Divider />
                      <Posts />
                    </Card>
            </Col>
            <Col md={3}>
              <Row>
                  <Col>
                    {width > 600 ? <Header>Your Community</Header> : null }
                  </Col>
              </Row>
              <Row>
                  {width > 600 ?
                    <Col style={{marginRight: '1rem'}}>
                      <Card style={{minHeight: "25vh", backgroundColor: "white", marginBottom: '0.5rem'}}>
                        <h5>Free produce&nbsp;<Link to={`/searchshares`}><FaSearch /></Link></h5>
                        <CommunityShares />
                      </Card>
                      <Card style={{height: "500px", color: "black", width: "100% !important", backgroundColor: "white", padding: "5px"}}>
                      <h5><FaInfoCircle style={{color: 'blue'}} />&nbsp;Information</h5>
                      <p>
                      <Button variant="outlined" as={Link} to="/community/members">
                        Members <FaUsers size={24} data-tip="Community Members" className="communityIcons"/>:
                      </Button>{user.community.people}<br/>
                      Tools <FaTools size={24} data-tip="Available Tools" className="communityIcons"/>: {user.community.tools}<br/>
                      Gardens <GiPlantsAndAnimals size={24} data-tip="Member Gardens" className="communityIcons"/>: {user.community.gardens}<br/>
                      Plants <FaSeedling size={24} data-tip="Member Plants" className="communityIcons"/>: {user.community.plants}<br/>
                      </p>
                      <ReactTooltip/>
                        <Link to="/resources">
                          <Button variant="primary">Search Resources</Button>
                        </Link><br/>
                      <h5>Community Area</h5>
                      <CommunityMap />
                      </Card>
                    </Col>
                    :
                    null
                  }
              </Row>
            </Col>
          </Row>
    </Container>
  );
})

export default Community;