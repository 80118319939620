import { Tooltip, Typography, Zoom } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useUser } from '../../context/user';
import { RiMailSendLine } from 'react-icons/ri';

export const InviteModal = ()  => {

  const { user, inviteFriend } = useUser();

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  let friend = {name, email, message};

  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      &nbsp;
      {user.verified ? 
        <Button style={{margin: '0 auto', marginBottom: '0.5rem'}} variant="primary" onClick={handleShow}>
          Invite Friends <RiMailSendLine />
        </Button>
      :
        <Tooltip title={<Typography color="inherit">Verify to invite friends</Typography>} arrow TransitionComponent={Zoom} aria-label="Verify reminder">
          <span>
            <Button disabled variant="primary">
                Invite Friend <RiMailSendLine />
            </Button>
          </span>
        </Tooltip>
      }

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton><Typography as="h3">Invite A Friend &nbsp;<RiMailSendLine /></Typography></Modal.Header>
        <Modal.Body>
          <Form.Control 
            label="Friends Name"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Control
            label="Friends Email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control
            as="textarea"
            placeholder="Message to friend (Optional)"
            onChange={(e) => setMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="primary"
            onClick={() => (inviteFriend(friend, handleClose))}>
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}