import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import {useUser} from './user';
import { useConfig } from './config';

export const MessageContext = createContext();

export function useMessage() {
  return useContext(MessageContext);
}

export const MessageProvider = ({children, messageFriendId}) => {
    const {api} = useConfig();

    const [ messages, setMessages ] = useState([]);
    const {user, refreshUser} = useUser();
    const userId = user.id;
    
    const [isMessageError, setIsMessageError] = useState(false);
    const [addMessageSuccess, setAddMessageSuccess] = useState("");

    const loadMessages = useCallback(() => {
        fetch(`${api}/user/${userId}/messages/${messageFriendId}/`, {userId}).then(response =>
            response.json().then(data => {
                    setMessages(data);
                    refreshUser(userId);
                })
            );
    }, [messageFriendId, userId, api, refreshUser]);


    function addMessage(newMessage, friendId, clear) {
        axios.post(`${api}/message/${userId}/${friendId}/`, {
            message: newMessage,
            friendId,
            userId
        }).then(result => {
            if (result.status === 200) {
                setIsMessageError(false);
                setAddMessageSuccess(true);
                setMessages([...messages, result.data]);
                clear();
                setTimeout(() => {
                    setAddMessageSuccess(false);
                }, 5000);
            } else {
                setIsMessageError(true);
            }
        }).catch(e => {
            setIsMessageError(true);
        });
    }

    return (
    <MessageContext.Provider value={{messages, loadMessages, setMessages, messageFriendId, addMessage, isMessageError, setIsMessageError, addMessageSuccess, setAddMessageSuccess}}>
        {children}
    </MessageContext.Provider>
    )
}