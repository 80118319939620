import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useUser } from './user';
import { useConfig } from './config';

export const PostContext = createContext();

export function usePost() {
  return useContext(PostContext);
}

export const PostProvider = ({children}) => {
    const {api} = useConfig();
    
    const {user} = useUser();
    const userId = user.id;

    const [isLoadingGarden, setIsLoadingGarden] = useState(false);
    const [posts, setPosts] = useState([]);
    const [postsToLoad, setPostsToLoad] = useState(5);
    const [friendsPosts, setFriendsPosts] = useState([]);

    const [userPosts, setUserPosts] = useState([]);
    const [profilePostsUserId, setProfilePostsUserId] = useState(null);

    const [gardenPosts, setGardenPosts] = useState([]);
    const [currentGardenId, setCurrentGardenId] = useState();

    const [isPostError, setIsPostError] = useState(false);
    const [addPostSuccess, setAddPostSuccess] = useState("");

    useEffect(() => {
        userId && fetch(`${api}/user/${userId}/community_posts/${postsToLoad}`, {userId}).then(response =>
            response.json().then(data => {
                setPosts(data);
            })
        );

        userId && fetch(`${api}/user/${userId}/friendsUpdates/${postsToLoad}`, {
            userId
        }).then(result => 
            result.json().then(data => {
                setFriendsPosts(data);
            })
        );

        profilePostsUserId && fetch(`${api}/user/${profilePostsUserId}/user_posts/${postsToLoad}`, {
            profilePostsUserId
        }).then(result => 
            result.json().then(data => {
                setUserPosts(data);
            })
        );

        if (currentGardenId !== undefined) {
            setIsLoadingGarden(true);
            axios.post(`${api}/garden/${currentGardenId}/posts/${postsToLoad}`, {userId}).then((response) => {
                setGardenPosts(response.data);
                setIsLoadingGarden(false);
            });
        }

    }, [userId, api, currentGardenId, postsToLoad, profilePostsUserId]);

    const addPost = useCallback((newPost, photo = null, postPublic = true, gardenId = null, parentId = null) => {
        return axios.post(`${api}/user/${userId}/post/`, {
            message: newPost,
            postPublic,
            userId,
            gardenId: gardenId === '999999' ? null : gardenId,
            photo,
            parentId
        }).then((result) => {
            if (result.status === 200) {
                setIsPostError(false);
                setAddPostSuccess(true);
                if (postPublic) {
                    if (gardenId !== null && gardenId !== "") {
                        setGardenPosts([result.data, ...gardenPosts]);
                        setPosts([result.data, ...posts]);
                    } else {
                        setPosts([result.data, ...posts]);
                    }
                }
                setTimeout(() => {
                    setAddPostSuccess(false);
                }, 5000);
                return result.data.id;
            } else {
                setIsPostError(true);
            }
        }).catch(e => {
            console.log(e);
            setIsPostError(true);
        });
    }, [api, gardenPosts, posts, userId])


    function addComment(newPost, parentId, friend = false, postPublic = true) {
        return axios.post(`${api}/user/${userId}/post/comment`, {
            message: newPost,
            userId,
            parentId,
            postPublic
        }).then((result) => {
            if (result.status === 200) {
                let newPosts = [...posts];
                newPosts[posts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                if (result.data.gardenId != null) {
                    let newGardenPosts = [...gardenPosts];
                    newGardenPosts[gardenPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                    setGardenPosts(newGardenPosts);
                }
                if (friend) {
                    let newFriendsPosts = [...friendsPosts];
                    newFriendsPosts[friendsPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                    setFriendsPosts(newFriendsPosts);
                }
                setPosts(newPosts);
            } else {
                setIsPostError(true);
            }
        }).catch(e => {
            setIsPostError(true);
            console.log(e);
        });
    }

    function editPost(data, cb = false) {
        return axios.post(`${api}/user/${userId}/post/edit`, {...data})
        .then((result) => {
            if (result.status === 200) {
                // let newPosts = [...posts];
                // newPosts[posts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                setPosts([result.data, ...posts]);
                if (result.data.gardenId) {
                    setGardenPosts([result.data, ...gardenPosts]);
                }

                if (cb === false) {
                    return;
                } else {
                    cb(false);
                }
            }
        })
    }

    function likePost(postId, toggle) {
        axios.post(`${api}/user/${userId}/post/like`, {
            postId
        }).then(result => {
            if (result.status === 200) {
                toggle(postId);
                let newPosts = [...posts];
                newPosts[posts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                setPosts(newPosts);
            } else {
                console.log(result);
            }
        })
    }


    function likeGardenPost(postId, toggle) {
        axios.post(`${api}/user/${userId}/post/like`, {
            postId
        }).then(result => {
            if (result.status === 200) {
                toggle(postId);
                let newGardenPosts = [...gardenPosts];
                newGardenPosts[gardenPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                setGardenPosts(newGardenPosts);
            } else {
                console.log(result);
            }
        })
    }

    function likeFriendsPost(postId, toggle) {
        axios.post(`${api}/user/${userId}/post/like`, {
            postId
        }).then(result => {
            if (result.status === 200) {
                toggle(postId);
                let newFriendsPosts = [...friendsPosts];
                newFriendsPosts[friendsPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                setFriendsPosts(newFriendsPosts);
            } else {
                console.log(result);
            }
        })
    }

    // function loadMoreUserPosts() {
    //     fetch(`${api}/user/${userId}/user_posts/${userPosts.length + 5}`, {
    //         userId
    //     }).then(result => 
    //         result.json().then(data => {
    //             setUserPosts(data);
    //         })
    //     );
    // }

    function removePost(postId, feed, parentId = null) {
        axios.post(`${api}/post/${postId}/remove`, {
            postId,
            userId
        }).then((result) => {
            if (result.status === 200) {
                if (feed === 'garden') {
                    if (parentId !== null) {
                        let newGardenPosts = [...gardenPosts];
                        newGardenPosts[gardenPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                        setGardenPosts(newGardenPosts);
                    } else {
                        var newGardenPosts = gardenPosts.filter(function(oldPosts) { return oldPosts.id !== postId });
                        setGardenPosts(newGardenPosts);
                    }
                } 
                if (feed === 'friends') {
                    if (parentId !== null) {
                        let newFriendsPosts = [...friendsPosts];
                        newFriendsPosts[friendsPosts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                        setFriendsPosts(newFriendsPosts);
                    } else {
                        var newFriendsPosts = friendsPosts.filter(function(oldPosts) { return oldPosts.id !== postId });
                        setFriendsPosts(newFriendsPosts);
                    }
                }
                if (feed === 'community') {
                    if (parentId !== null) {
                        let newPosts = [...posts];
                        newPosts[posts.map(function(e) { return e.id; }).indexOf(result.data.id)] = result.data;
                        setPosts(newPosts);
                    } else {
                        var newPosts = posts.filter(function(oldPosts) { return oldPosts.id !== postId });
                        setPosts(newPosts);
                    }
                }
            } else {
                setIsPostError(true);
            }
        }).catch(e => {
            setIsPostError(true);
        });
    }

    return (
    <PostContext.Provider value={{posts, postsToLoad, setPostsToLoad, isLoadingGarden, gardenPosts, friendsPosts, setCurrentGardenId, setPosts, addPost, addComment, editPost, removePost, likePost, likeFriendsPost, likeGardenPost, isPostError, setIsPostError, addPostSuccess, setAddPostSuccess, userPosts, setUserPosts, profilePostsUserId, setProfilePostsUserId}}>
        {children}
    </PostContext.Provider>
    )
}

export const withPosts = (Wrapped) => (props) => {
    return(
        <PostProvider>
            <Wrapped {...props} />
        </PostProvider>
    )
}