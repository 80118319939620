import React, {useState, useEffect, useRef} from "react";
import { Modal, Form, Row, Col, Card } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useMessage } from "../../context/messages";
import { useUser } from "../../context/user";
import { ProfilePhoto, NavProfilePhoto } from "../Profile/ProfileImage";
import { timeConverter } from "../../core/utils";

export const ChatModal = (props) => {

    const {user} = useUser();

    const {messages, addMessage, loadMessages} = useMessage();

    const [newMessage, setNewMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);

    const messagesEndRef = useRef(null);


    function clear() {
      setNewMessage("");
    }

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const handleAddMessage = () => {
      if (newMessage !== "" && newMessage !== null) {
        addMessage(newMessage, props.friend.id, clear);
        setErrorMessage(false);
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } else {
        setErrorMessage(true);
      }
    }

    useEffect(() => {
      if (props.show) {
        loadMessages();
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
        const interval = setInterval(() => {
          loadMessages();
        }, 3000);
        return () => clearInterval(interval);
      }
      if (newMessage !== "" && newMessage !== null) {
        setErrorMessage(false);
      }
      // eslint-disable-next-line
    }, [props.show, setErrorMessage]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Conversation with {props.friend.name} <ProfilePhoto person={props.friend} />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight:"30vh", overflowY: "scroll"}}>
                <Form>
                    <Row>
                      <Col>
                          {messages.map((message) => (
                                <React.Fragment key={message.id}>
                                    <Card style={{border: "none"}}>
                                      <Row>
                                        <Col>
                                            {message.toUserId === user.id ?
                                              <Card.Body style={{padding: "0.5rem", margin: "0.5rem", textAlign: "left"}}>
                                                <NavProfilePhoto person={props.friend}/><span style={{backgroundColor: "lightgrey", borderRadius: "5px", padding: "0.3rem", lineHeight: "1.5rem"}}>{message.message}</span><br/><div style={{fontSize: '0.7rem'}}>{timeConverter(message.created)}</div>
                                              </Card.Body>
                                            :
                                              <Card.Body style={{padding: "0.5rem", textAlign: "right", margin: "0.5rem"}}>
                                                <span style={{display: 'inline', backgroundColor: "lightblue", borderRadius: "5px", padding: "0.3rem", lineHeight: "1.5rem"}}>{message.message}</span> <div style={{display: 'inline', fontSize: '0.7rem'}}><NavProfilePhoto person={user}/><br/>{timeConverter(message.created)}</div>
                                              </Card.Body>
                                            }
                                        </Col>
                                      </Row>
                                    </Card>
                                </React.Fragment>
                              )
                          )}
                          <div ref={messagesEndRef} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        
                      </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
              <Form.Control
                as="textarea"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={`send a message to ${props.friend.name}`}
              />
              { errorMessage ? 
                <span>Type something to send it!</span>
              :
                null
              }
              <Button
                onClick={() => {handleAddMessage()}}
                color="primary"
                variant="contained"
              >
                Send
              </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChatModal;